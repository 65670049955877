@import "../../../../../App";
@import "../../../../../colors";

.audit-summary-card {
  display: flex;

  .cfa-card-content {
    height: 100%;
    display: flex;
  }

  .summary-data-wrapper {
    display: flex;
    flex-direction: column;

    .action-buttons {
      margin-top: auto;
    }
  }

  hr {
    // needed for <hr /> element in flex-column container
    margin-left: 0;
    margin-right: 0;
  }

  &.banner-applied {
    padding-top: 0;
  }

  #audit-info-properties {
    max-width: 75%;
  }

  #total-findings-count {
    margin-top: 1.5rem;
  }

  #score-hover-description {
    cursor: help;
    margin-left: auto;
    display: inline-flex;
    align-items: center;
  }

  .performance-chart-container {
    height: 12.565rem;
    width: 20rem;
    margin: 1em 0 2em -1em;
    padding-right: 9.5em;
  }

  .unavailable-score {
    color: $secondary-purple;
    font-size: 0.9rem;
    margin-top: 2rem;
  }

  .score-indicator-icon {
    vertical-align: top;
    margin-left: -0.5rem;
  }

  .score-rating {
    margin: auto;
    width: fit-content;
    padding: 0.2rem 1rem;
    border-radius: 3rem;
    font-size: 0.9rem;
    font-family: "Apercu-bold";
  }

  .score-dark-text {
    color: $default  !important;
  }

  .score-light-text {
    color: $white  !important;
  }

  .tooltip-inner p {
    color: $dark-gray;
  }

  .needs-improvement {
    padding: 10px 0;
  }

  .cfa-button {
    padding: 0 15px;
  }

  #no-findings-wrapper {
    padding: 1.5rem 0;
  }

  .menu-button-wrapper {
    margin-left: auto;
  }

  &__toast-spinner-container {
    display: flex;
    height: 1.82em;
    width: 2em;
  }
}