@import "../../../../App";

.perfect-audit-card {
  margin: 2rem 0 0;
  min-width: 15rem;
  margin-bottom: 1.5rem;

  .card-title {
    text-align: left;
    font-size: 1rem;
    text-transform: uppercase;
  }

  @media (width <= 576px) {
    min-height: 17rem !important;
  }

  .perfect-audit-logo-col {
    align-self: center;
    margin-bottom: 1rem;
  }

  .perfect-audit-logo-row {
    justify-content: center;
  }

  h2 {
    padding: 0 3rem;
    text-align: center;
    margin: 1.25rem auto;
    font-size: 1rem;
    font-family: "Apercu-Bold";
  }

  .view-past-findings-button {
    margin: 1rem auto;
    padding-top: 0.75rem;
    font-size: 0.8rem;
    font-weight: bold;
    align-self: center;
    height: 2.75rem;
    width: 13rem;
    color: $white;
    border-color: $default;
    background-color: $default;
    border-radius: 3rem;
    text-align: center;

    &:hover {
      border: $white;
      background-color: $secondary-purple;
      text-decoration: none;
    }
  }
}
