.cancel-next-button {
    &__container {
        display: flex;
        @media (width >= 500px) {
            justify-content: end;
        }
        @media (width <= 500px) {
            justify-content: space-around;
        }
    }

    &__next-btn {
        width: 200px;
    }
}
