@import '../../../../colors';

.reason-for-review {
  &__container {
    margin-bottom: 2em;
  }


  &__title {
    color: $cfa-black;
    font-family: 'Apercu';
    font-weight: bold;
    font-size: 1.2rem;
  }

  &__sub-title {
    font-size: 15px;
  }

  &__input-box {
    width: 100%;
    min-height: 8em;
    border: gray 1px solid;
    border-radius: 3px;
    padding: 1em;
    outline-color: $cfa-blue-dark;
  }
}