@import "../../../../colors";

.questionnaire-container {
  margin: 3em 0 0;

  .questionnaire-category {
    background-color: $white;
    padding: 1.25em;
    border-radius: 1.25em;
    margin: 1em 0;
  }

  .questionnaire-header-description {
    color: $default;
  }
}
