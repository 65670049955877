@import '../../../../colors';

.question-and-appeal {
  &__container {
    background-color: $white;
    max-width: 70em;
    padding: 2rem 7rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (width <= 500px) {
      padding: 2rem 3rem;
    }
  }

  &__button-container {
    display: flex;
    padding-top: 4rem;
    justify-content: center;

    @media (width <= 500px) {
      flex-direction: column;
    }
  }

  &__finding-id {
    font-weight: 600;
    color: $light-gray;
    padding-bottom: 1rem;
  }

  &__question-button {
    width: 19rem;
  }

  &__appeal-button {
    margin-left: 1rem;
    width: 19rem;
    @media (width <= 500px) {
      margin-top: 1rem;
      margin-left: 0;
    }
  }
}