@import "../../../App";

.appeals-card {
    &__container {
        display: flex;
        width: 100%;
        padding: 1em 1em 1em 1.5em;
        margin-bottom: 2em;
        margin-top: 1em;
        background: $white;
        box-shadow: 0 7px 20px rgb(0 0 0 / 0.16);
        border-radius: 8px !important;
    }

    &__content {
        display: flex;
        flex-direction: column;
        padding-right: 1em;
        flex-grow: 1;
    }

    &__tag-header {
        display: flex;
        flex-wrap: wrap;
    }

    &__header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__tag-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0.5em 0 0;
    }

    &__tag-title {
        font-weight: bold;
        font-size: 0.875em;
        color: $cfa-black;
        margin: 0 0.369em 0 0;
    }

    &__tag-data {
        font-size: 0.875em;
        color: $cfa-black;
        margin: 0;
    }

    &__header-container {
        display: flex;
    }

    &__finding-title {
        margin-top: 0.71em;
        margin-bottom: 0.25em;
        color: $cfa-black;
        font-weight: bold;
        font-size: 1.5rem;
    }

    &__store-title {
        margin-top: 0.25em;
        margin-bottom: 0;
        color: $cfa-black;
        font-weight: bold;
        font-size: 1.125rem;
    }

    &__details-info-container {
        margin-top: 1em;
        margin-right: 2em;

        @media (width <=710px) {
            max-width: 100%;
        }
    }

    &__details-info-title {
        font-size: 1rem;
        font-weight: bold;
        line-height: 20px;
        color: $dark-gray;
    }

    &__details-info-data {
        color: $cfa-black;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.25em;
        word-wrap: break-word;
        width: 100%;
        overflow-wrap: anywhere;
    }

    &__details-info {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @media (width >=711px) {
            max-width: 85%;
        }

        @media (width <=710px) {
            max-width: 100%;
        }
    }

    &__details-attachment-container {
        margin-right: 2em;
        max-width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    &__details-attachment-link {
        padding-right: 1em;
        padding-bottom: 0.5em;
        color: $cfa-black;
        width: max-content;
        text-decoration: underline;
    }

    &__buttons-divider {
        border-top: 0.15em solid $amendments-button-divider;
        margin-top: 1em;
        margin-bottom: 1em;
    }

    &__comments-findings-btns-container {
        order: 8;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-flow: row wrap;
        gap: 1rem;
    }

    &__actions-buttons-container {
        display: flex;
        align-items: center;
        gap: .5rem;

        @media (width <=992px) {
            flex: 1;
        }

        @media (width <=710px) {
            flex-direction: column;
        }

        &--comment-btns {
            gap: 2rem;
        }
    }

    &__open-comments-box-btn {
        letter-spacing: 0.05em;
        padding: 0 !important;
        min-width: 0 !important;
        border-radius: 0 !important;

        &--disabled {
            color: $faded-dark-blue;
        }
    }

    &__icon {
        path:not(mask path) {
            fill: none;
        }

        &--deny {
            color: $cfa-red-dark;
        }

        &--approve {
            color: $cfa-success-green;
        }
    }

    &__flex-button {
        display: flex;
        justify-content: center;
        align-items: center;

        @media (width <=992px) {
            flex: 1;

            button {
                min-width: 0 !important;
            }
        }
    }

    &__modify-btn {
        letter-spacing: 0.05em;
    }

    &__actions-btn-deny-text {
        color: $cfa-red-dark !important;
        letter-spacing: 0.05em;
    }

    &__actions-btn--approve {
        border-color: $cfa-success-green !important;
        vertical-align: inherit;
    }

    &__actions-btn-approve-text {
        color: $cfa-success-green !important;
        letter-spacing: 0.05em;
    }

    &__right-arrow {
        text-align: right;
        align-self: center;    
    }
}