@import "../../../../App";

.requested-appeals {
    &__header-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 1em;
    }

    &__title-container {
        margin: 0
    }

    &__request-count {
        font-family: "Apercu";
        font-style: italic;
        font-weight: 400;
        font-size: 14px;
        color: #5b6770;
        margin-bottom: 0;
        margin-top: 0.25em;
    }

    &__filters-container {
        display: flex;
        align-items: baseline;
        flex-wrap: nowrap;
        gap: 1rem;
        @media (width <= 710px) {
            margin-top: 2em;
        }
    
    }

    &__filter-button {
        height: 2.5rem;
        min-width: 6.5em !important;
    }

    &__no-results-container {
        display: flex;
        justify-content: center;
        margin-top: 3em;
        text-align: center;
    }
}
