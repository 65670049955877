.finding-overview-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  &__badges {
    display: flex;
    flex-wrap: wrap;
  }
  
  &__answer-container {
    display: flex;
    min-height: 2rem;
    padding-top: .5rem;
  }

  &__answer {
    padding-left: .5rem;
    line-height: 2.1rem;
  }
  
}