@import '../../../../colors';

.request-amendment-form {
  &__container {
    background-color: $white-gray;
    margin: 3em auto 5em;
    max-width: 70em;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }

  &__page-date-text {
    color: $cfa-black;
  }

  &__page-title {
    color: $cfa-black;
    @media (width >= 500px) {
     font-size: 2em;
    }
    @media (width <= 500px) {
      font-size: 1.75em;
    }
  }

  &__panel-container {
    background-color: $white;
    padding: 2em;
  }

  &__link-btn {
    display: flex;
    letter-spacing: 0.05em;
    background-color: transparent;
    color: $cfa-blue-dark;
    border-color: transparent;
    font-weight: bold;
    padding-right: 0;
    border: 0;
    @media (width <= 500px) {
      padding-left: 0;
    }
  }

  &__submit-btn {
    font-size: 1rem;
    font-weight: bold;
    align-self: center;
    color: $white;
    background-color: $cfa-blue-dark;
    border-radius: 24px;
    padding: 0.4em 1.5em;
    border-color: transparent;

    &--disabled {
      color: $light-gray;
      background-color: $disabled-gray;
      border-color: transparent;
    }
  }

  &__submit-btn-container {
    @media (width >= 500px) {
      justify-content: end;
    }
    @media (width <= 500px) {
      justify-content: space-around;
    }
  }

  &__cancel-btn {
    display: flex;
    letter-spacing: 0.05em;
    background-color: transparent;
    color: $cfa-blue-dark;
    border-color: transparent;
    font-weight: bold;
    @media (width >= 711px) {
      margin: 0.4em 2.53em 0 0;
    }
    @media (width <= 710px) {
      margin: 0.4em 3em 0 0;
    }
  }

  &__back-navegation {
    margin-bottom: 3em;
  }

  &__title--disabled {
    border: #5b6770 solid 1px;
    padding: 0.5em;
    color: black;
    background-color: #d3d3d338;
    margin-bottom: 1.8em;
    border-radius: 4px;
  }

  &__step-title {
    color: $cfa-black;
    font-weight: bold;
    margin: 0.61em 0 1.8em;
  }

	&__file-uploader-container {
		margin: 1rem 0 2rem;
	}
}