@import "../../../../../App";

.requested-appeals-widget {
    display: flex;
    max-width: 30em;

    &__container {
        display: flex;
        flex-direction: column;
    }

    &__header-row {
        margin: 0;
        align-items: center;
        justify-content: space-between;
    }

    hr {
        // needed for <hr /> element in flex-column container
        margin: 0;
    }

    &__view-button {
        padding: 0;
    }

    &__column-header {
        margin-top: 1em;
        margin-right: 4em;
    }

    &__appeals-card-container {
        max-height: 15em;
        overflow-y: auto;
        overflow-x: hidden;
    }

    &__appeals-card-info-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 1em 0 0.5em;
    }

    &__appeals-card-description {
        @media (width >= 711px) {
            flex-basis: 70%;
        }

        @media (width <= 710px) {
            flex-basis: 60%;
        }
        
    }

    &__label {
        font-family: "Apercu";
        font-style: normal;
        font-weight: 500;
        font-size: 0.75rem;
        margin: 0 1.5em 0 0;
        padding: 0.5em 1em;
        border-radius: 8px;
        height: fit-content;
    }

    &__approved-label {
        background-color: rgb(7 126 76 / 0.15);
        color: $cfa-success-green !important;
    }

    &__denied-label {
        background-color: rgb(91 103 112 / 0.15);
        color: $default !important;
    }

    &__pending-label {
        background-color: rgb(255 181 73 / 0.15);
        color: $honey-mustard !important;
    }
}
