@import "../../../../../App";

.finding-overview-container {
    display: flex;
    justify-content: space-between;
    
    .finding-card {
        &__title-container {
            display: flex;
            justify-content: space-between;
        }

        &__title {
            font-family: "Apercu";
            font-style: normal;
            font-weight: bold;
            color: #595959;

            @media (width >= 711px) {
                max-width: 70%;
                font-size: 1.5rem;
            }

            @media (width <= 710px) {
                max-width: 65%;
                font-size: 1.125rem;
            }
        }

        &__label {
            font-family: "Apercu";
            font-style: normal;
            font-weight: normal;
            font-size: 0.75rem;
            letter-spacing: 0.15em;
            margin-bottom: 0.5em;
            min-width: 6.6em;

            @media (width >= 711px) {
                margin-left: 1em;
            }

            @media (width <= 710px) {
                margin-left: 0;
            }
        }

        &__details-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &__detail {
            color: $cfa-gray;
            margin-top: 1em;
            flex-basis: 100%;
        }

        &__label-container {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }

        &__finding-forward-arrow {
            text-align: right;
            align-self: center;
        }
    }
}