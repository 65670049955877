@import '../../../../colors';

.performance-level-graph {
    &__container {
      position: relative;
      display: flex;
      height: 80%;
      min-height: 25em;
      margin-top: 4.5em;
      justify-content: flex-end;
  
      @media (width <= 768px) {
        margin-top: 2.5em;
      }
    }
  
    &__left-axis {
      &-container {
        margin-right: -2.8em;
      }
  
      &-title {
        writing-mode: vertical-lr;
        transform: rotate(-180deg);
        font-family: 'Apercu-bold';
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        margin: -6.7em 7.5em 0 0;
  
        @media (width <= 820px) {
          display: none
        }
      }
  
      &-value-container--elite {
        margin-top: 2.3em;
        background-color: $secondary-green;
        border-radius: 10px;
        width: 20px;
        height: 20px;
  
        p:nth-child(2) {
          position: absolute;
          margin: -2.8234em 0 0 -2.69em;
          color: $secondary-green;
          font-size: 12px;
        }
      }
  
      &-value-container--good {
        background-color: $cfa-green-dark;
        border-radius: 10px;
        margin-top: 5px;
        margin-bottom: 16px;
        width: 20px;
  
        >p {
          margin: 3px 0 7px;
          padding-top: 5px;
        }
  
        p:first-child {
          margin: 8px 0 5px;
          text-align: center;
        }
  
        p:last-child {
          padding: 3px 0 6px;
        }
  
        p:nth-child(2) {
          position: absolute;
          margin: -0.1234em 0 0 -3.2em;
          color: $cfa-green-dark;
          font-size: 12px;
        }
  
      }
  
      &-value-container--fair {
        background-color: $honey-mustard;
        border-radius: 10px;
        margin-top: -13px;
  
        >p {
          margin: 6px 0 13px;
          padding-top: 5px;
        }
  
        p:first-child {
          margin: 8px 0 -3px;
        }
  
        p:last-child {
          padding: 0 0 6px;
        }
  
        p:nth-child(2) {
          position: absolute;
          margin: -0.1234em 0 0 -2.52em;
          color: $honey-mustard;
          font-size: 12px;
        }
      }
  
      &-value-container--unsatisfactory {
        background-color: $fs-medium;
        border-radius: 10px;
        margin-top: -13px;
  
        p:first-child {
          padding-top: 3px;
          margin: 16px 0 10px;
        }
  
        p:last-child {
          padding: 0 0 6px;
        }
  
        p:nth-child(2) {
          position: absolute;
          margin: -1.31em 0 0 -7.2em;
          color: $fs-medium;
          font-size: 12px;
        }
      }
  
      &-value-container--sub-standard {
        background-color: $primary;
        border-radius: 10px;
        width: 20px;
        height: 20px;
        margin-top: -10px;
  
        p:nth-child(2) {
          position: absolute;
          margin: -2.8234em 0 0 -6.9em;
          color: $primary;
          font-size: 12px;
        }
      }
  
      &-text-number {
        font-size: 12px;
        color: $white;
        text-align: center;
      }
    }
  }