@import "../../../../App";

.amendment-container {
  background-color: $white-gray;
  max-width: 50em;
  margin-top: 3em;
  margin-bottom: 5em;

  @media (width >= 992px) {
    max-width: 70em;
  }

  .desktop-back-link {
    font-size: 1rem;
    text-align: left;
    color: $cfa-blue-dark;
  }

  .desktop-back-arrow {
    margin-right: 0.1em;
  }

  .amendments-title {
    color: $amendments-title;
    margin-bottom: 0.5em;
    font-size: 36px;
  }

  .mobile-selector-label {
    font-size: 1.25rem;
    font-family: "Apercu-Medium";
    text-align: left;
    padding-bottom: 0.5em;
    width: 15em;
  }

  .empty-amendments {
    margin: 2em 0 0 -1em;
  }

  .amendments-description {
    font-size: 1rem;
    font-family: "Apercu-medium";
    margin-top: 1em;
  }

  .desktop-sub-header {
    margin-top: 1em;
  }

  .amendments-header-container {
    margin-left: 2.3em;
  }

  .appeal-form-button-container {
    max-width: 16.25em !important;
    margin-top: 0.15em;
  }

  &__no-amendments-title {
    padding: 3em 0;
    text-align: center;
    background: $white;
    border-radius: 8px;
  }

  .amendments-filters {
    &__container {
      padding-bottom: 1em;
      display: flex;
      align-items: center;
    }

    &__selector {
      width: 12rem !important;
    }

    &__filter-button {
      height: 2.5rem;
      min-width: 6.5em !important;
    }

    &__reset-filters-button {
      color: $navy;
      font-size: 1rem;
      font-weight: 700;
      background: none;
      border: none;
      margin-bottom: 0.75em;
      padding: 0;
      cursor: pointer;
    }
  }

  .amendments-pagination {
    color: $cfa-blue-dark;

    &__container {
      justify-content: center;
    }

    &--ellipsis-text {
      text-align: center;
    }

    &__page-item-li {
      border-radius: 4px;
      margin: 0 3px;
    }

    &__page-link-a {
      color: $default;
      border-radius: 4px;
    }

    &--active {
      color: $cfa-blue-dark;
      border: $cfa-blue-dark 1px solid;
      border-radius: 4px;
    }
  }

  .amendments__content-container {
    margin-left: 2.3em;
    margin-right: 1em;

    @media (width <= 575px) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .amendments__filter-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    align-items: flex-end;
  }

  .amendments-subtitle {
    color: $amendments-title;
    font-size: 1.875rem;
    font-weight: bold;
  }

  .amendments-subtitle-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: row wrap;
  }

  .amendments-count {
    font-family: "Apercu";
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    color: #5b6770;
    margin-bottom: 0;
  }

  .amendments-tab-links__container {
    margin-bottom: 3em;
    margin-left: 2.3em;
    margin-right: 1em;

    @media (width <= 575px) {
      margin-left: 0.3em;
      margin-right: 0;
    }

    .amendments-tab-links__title {
      font-weight: 100;
      font-size: 22px;
      margin-bottom: 3px;
      margin-right: 4.5em;
      padding-left: 0;
      cursor: pointer;

      @media (width <= 575px) {
        font-size: 15px;
        margin-right: 1.1em;
      }
    }

    .amendments-active-tab {
      color: $cfa-blue-dark;
    }

    .amendments_tab-col {
      padding: 0;
    }

    .amendments_tab-col .amendments-active-tab::after {
      display: flex;
      position: relative;
      z-index: 4;
      content: "";
      height: 6px;

      /* adjust this to move up and down. you may have to adjust the line height of the paragraph if you move it down a lot. */
      bottom: -6px;
      border-radius: 8px;
      margin: 0 auto;
      left: 0;
      right: 0;
      background: $cfa-blue-dark;
    }

    .amendments-tab-links__container--border {
      height: 3px;
      width: 99%;
      background: $faint-gray;
      border-radius: 4px;
      position: relative;
    }
  }

  .amendments-tab--disabled {
    opacity: 0.33;
  }

  .amendments-tab__sub-title {
    margin: 1em 0 3.2em 0.2em;
    color: $dark-gray;
  }
}
