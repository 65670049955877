.risk-level-bar-graph {
    &__graph-container {
        height: 390px;
        width: 80%;
        margin: 1em 0 0 4.5em;

        // Break point
        // iPad
        @media (width <= 1022px) {
            margin: 0.1em 0 0 -2.5em;
        }

        // Break point
        //Mobile
        @media (width <= 767px) {
            width: 130%;
        }
    }

    &__tooltip-container {
        background: white;
        padding: 9px 12px;
        border: 1px solid white;
        border-radius: 6px;
        font-family: "Apercu";
        font-size: normal;
        text-align: left;
        width: 13.5em;
        height: 7.5em;
        filter: drop-shadow(0 4px 12px rgb(91 103 112 / 0.24));
    }

    &__tooltip--bullet {
        position: relative;
        top: -1px;
        display: inline-block;
        width: 12px;
        height: 12px;
        vertical-align: middle;
        border-radius: 50%;
        margin-right: 0.51em;
    }

    &__tooltip--title {
        font-weight: 700;
        font-size: 16;
    }
}