@import "../../../App";

.QuestionsFilter {
  border: none;
  padding-left: 2em;

  .sub-header {
    font-size: 0.9em;
    font-family: "Apercu-bold";
    text-align: left;
    color: $dark-gray;
    margin-bottom: 0.5em;
  }

  .filter-list {
    padding: 0 0.5em 0.5em;
    margin: 0 0.5em 0.5em;

    .filter-by {
      margin: 1.5em 0 0.5em -2.5em;
      font-size: 0.92rem;
    }
  }

  h2 {
    font-size: 14px;
    margin: 0;
  }

}

.question-filter-container {
  margin-top: 1em;
}
