.send-reminder-button {
  .button-loading {
    margin: auto;
  }
}

.send-reminder-description-row {
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
}

.send-reminder-button-row {
  margin-top: 1rem;
  margin-bottom: 0.3rem;
  padding-left: 1rem;
  padding-bottom: 0.5rem;
}
