@import '../../../../../../colors';

.oc-notes-card {
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__switch {
    margin-right: 2em;
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
    padding-left: 1rem;
    padding-bottom: 0.5rem;
  }

  &__row {
    margin-left: 0;
    margin-right: 3em;
  }

  &__message {
    color: $cfa-gray;
    font-size: 1em;
  }

  &__no-data-card {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }

  &__no-data-card-text {
    text-align: center;
  }
}

@media (width <=810px) {
  .oc-notes-card {
    width: auto;
  }
}
