@import "../../../../colors";

.finding-occurrence {
  background-color: rgb(243 243 243);
  margin-bottom: 2em;
  border-radius: 8px;
  @media (width >= 711px) {
    margin: 0 2.5em 2em 0;
    width: 290px;
  }
  @media (width <= 710px) {
    margin: 0 0 2em;
    width: 100%;
  }
  
  border: 1px solid rgb(243 243 243);

  &__active {
    box-shadow: 0 0 0 6px $teal-outline;
    border: 1px solid transparent;
  }

  &__inner-container {
    padding: 0.52em;
  }

  &__inner-container__active {
    border: 1px solid $cfa-blue-dark;
    border-radius: 8px;
    height: 100%;
  }

  &__text-lable {
    font-size: 12px;
    margin: 0;
  }

  &__text-contenido {
    font-size: 16px;
    margin: 0;
    margin-bottom: 0.51em;
  }

  &__checkbox-container {
    display: flex;
    justify-content: end;
  }

  &__checkbox {
    margin: 0 0.75em -1.5em 0;
    background-color: $disabled-gray;
  }
}
