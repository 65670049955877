@import "../../../App";

.amendment-comment-card {
  margin: 1.5em;
  padding: 24px;

  &__buffer {
    background-color: $white;
  }

  &__container {
    background-color: $white;
    border: 1px solid #cccfd0;
    box-shadow: 0 2px 4px rgb(91 103 112 / 0.24);
    border-radius: 4px;
    margin: 1em 0.25em;
    padding: 0.5em 1em;
    font-weight: bold;
    font-size: 1rem;
    max-width: 99%;
  }

  &__username {
    font-weight: bold;
    font-size: 1rem;
  }

  &__denial {
    font-weight: bold;
    font-size: 1rem;
    color: $cfa-red-dark;
  }

  &__approval {
    font-weight: bold;
    font-size: 1rem;
    color: $cfa-success-green;
  }

  &__time {
    font-weight: 400;
    font-size: 1rem;
    color: $dark-gray;
  }

  &__comment-container {
    display: flex;
    margin-top: 0.25em;
  }

  &__comment {
    font-weight: 400;
    margin-top: 0.25em;
    margin-bottom: 0.5em;
  }

  &__comment-status {
    font-weight: bold;
    margin-top: 0.25em;
    margin-bottom: 0.5em;
    margin-right: 0.15em;
  }

  &__top-info-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
  }
}