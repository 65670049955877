@import "../../../../colors";

.smart-shop {
  &__icon {
    width: 2.5rem;
    height: 2.5rem;
    color: #004f71;
    margin-bottom: .5rem;
  }

  &__coming-soon-row {
    display: flex;
    margin-top: 5rem;
    justify-content: center;
  }

  &__coming-soon {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__header-row {
    margin-bottom: 2rem;
  }

  &__page-label{
    margin-top: 1rem;
  }

  &__inquiry-button {
    width: 10rem;
  }

  &__button-group {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  &__dropdown-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
  }

  &__inquiry-button-container {
    display: flex;
    flex-direction: column;
  }

  &__tableau {
    display: block;
    visibility: visible;
    max-width: none;
    opacity: 1;
    margin: auto -170px;
  }

  &__no-reports-container {
    height: 30rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  &__analytics_link {
    color: $navy;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.9rem;
  }

  &__celebrate-icon {
    color: $navy;
  }

  &__no-reports {
    padding-top: .5rem;
  }

  &__report_title {
    padding-left: 1rem;
  }
}