@import "../../../../App";

.filter-box {
    max-width: 1200px;
    margin: 1em 0;
    background-color: $white;
    padding: 2em;
    border-radius: 8px;

    &__headers {
        display: flex;
        justify-content: space-between;
    }

    &__subtitle {
        font-family: "Apercu";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: #000;
    }

    &__x-button {
        min-width: 0 !important;
        color: $navy;
        letter-spacing: 0.1em;
        font-weight: bold;
        background-color: $white;
        border: none;
    }

    &__container {
        display: flex;
        justify-content: space-between;
        flex-flow: column wrap;
    }

    &__filter-container {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
    }

    &__button-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        margin-top: 20px;
        @media (width >= 711px) {
            justify-content: flex-end;
        }
    }

    &__reset-button {
        @media (width >= 711px) {
            min-width: 6.5em !important;
            margin: 0 1em;
        }

        margin: 0 1em 2em;
    }

    &__apply-button {
        @media (width >= 711px) {
            min-width: 6.5em !important;
        }
    }

    &__label {
        font-family: "Apercu";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #5b6770;
        margin: 0 0 0.25em 0.25em;
    }

    &__selector {
        padding-right: 1.5em;
        padding-bottom: 1.5em;
        min-width: 16.75em;
    }

    &__date-selector {
        width: 15.25em;
    }

    &__badge-container {
        display: flex;
        flex-wrap: nowrap;
        margin-top: 1em;
        margin-bottom: 1em;
    }

    &__badge {
        margin: 0 0.5em 0 0;
    }

    &__date-picker {
      label {
        margin-bottom: 0; // required to align the datepicker with the other selectors
      }
      @media (width <= 710px) {
        max-width: 100%;
        width: 100%;
      }
    }

    .cfa-dropdown-wrapper {
      display: flex;
      flex-wrap: wrap;
      width: 16rem;

      @media (width <= 710px) {
        max-width: 100%;
        width: 100%;
      }
    }
}
