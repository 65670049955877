.calendar-component-container {
  color: #392e2e;
  max-width: 600px;
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.month-navigation {
  background-clip: content-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.month-label-container {
  margin: auto;
}

.calendar {
  position: relative;
  border-radius: 10px;
  border-width: 1px;
  padding: 5px;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  margin: 2vh;
}

.calendar-blocks {
  position: relative;
  padding: 5px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.weekrow {
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  border-radius: 5px;
}

.weekday {
  height: 35px;
  display: grid;
  justify-content: center;
  align-items: center;
}

.day {
  height: 2rem;
  justify-content: center;
  align-items: center;
  padding: 1px;
  background-clip: content-box;
  display: flex;
}

.day-block {
  background: #5b6770;
  height: 1.5rem;
  width: 100%;
}

.assessment-complete {
  background: #3eb1c8;
  background-clip: content-box;
}

.week-start-date-container {
  padding-top: 5px;
  padding-bottom: 5px;
  background-clip: content-box;
  border-radius: 8px;
  grid-row: 1/ 2;
}

.week-day-labels-container {
  grid-column: 2 / 9;
  grid-row: 1/ 2;
}

.day:not(.today):hover {
  background-clip: content-box;
}

.legend-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5em;
  margin-top: 1vh;
}

.legend-item {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 8px; // Spacing between color indicator and text
}

.legend-color {
  width: 16px; // Width of the color box
  height: 16px; // Height of the color box
  display: inline-block;
  border-radius: 10px;
}

.loading-container {
  grid-column: 1 / -1; /* Span the full width of the grid */
  display: flex;
  justify-content: center;
  align-items: center;
}
