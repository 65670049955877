/* Colors */

$primary-btn-light: #f03961;
$primary-btn-dark: #c00f2f;
$primary: #dd0031;
$primary-dark: #af272f;
$default: #5b6770;
$orange: #cf803b;
$white-gray: #e5e5e5;
$dark-gray: #3c3c3c;
$light-gray: #8e969c;
$disabled-gray: #eaeaea;
$nav-border: #f0eded;
$gray-border: $nav-border;
$navy: #004f71;
$teal: #3eb1c8;
$teal-outline: #bee7ee;
$soft-teal: #aee1ea;
$m-soft-teal: #e3f8fb;
$white-gray: #f5f5f5;
$faint-gray: #ccc;
$secondary-green: #00635b;
$light-green: #00635B26;
$secondary-purple: #994878;
$light-purple: #99487826;
$white: #fff;
$cfa-gray: #5B6770;
// Out of scope, but should be replaced by $cfa-green-dark
$success: #249E6B;
$cfa-success-green: #077E4C;
$spicy-honey-mustard: #d18d2a;
$honey-mustard: #ffb549;
$honey-mustard-lite: rgb(255 181 73 / 0.15);
$paste: #f5e3cc;
$barbecue: #6e3b3b;
$link-blue: #007bff;
$cfa-red-dark: #B00027;
$cfa-green-dark: #249E6B;
$amendments-title: #2d2d2d;
$done-button-disabled: #a7acaf;
$amendments-button-divider: #ececec;
$light-blue-gray: #ced4da;
$faded-dark-blue: #004e71a6;

/* Colors for SAFE Question Risk Levels */
$immediate: #af272f;
$high: #ffb549;
$medium: #004f71;
$low: #3eb1c8;
$informational: #5b6770;

/* Colors for SAFE Question Category */
$cleaning-sanitation: #994878;
$cross-contamiination: #fa9370;
$health-hygiene: #00635b;
$pests: #249e6b;
$time-temperature: #5d3754;
$cfa-blue-dark: #004e71;
$cfa-black: #1E272F;

/* Colors for Food Safety Labels */
$fs-default: #006B9A;
$fs-default-bg: rgb(0 107 154 / 0.15);
$fs-immediate: #B6072F;
$fs-immediate-bg: rgb(182 7 47 / 0.15);
$fs-high: #DD0031;
$fs-high-bg: rgb(221 0 49 / 0.15);
$fs-medium: #E35205;
$fs-medium-bg: rgb(227 82 5 / 0.15);
$fs-low: #FFB549;
$fs-low-bg: rgb(255 181 73 / 0.15);
$fs-informational: #004F71;
$fs-informational-bg: rgb(0 79 113 / 0.15);
$fs-time-temperature: #00635B;
$fs-time-temperature-bg:rgb(0 99 91 / 0.15);
$fs-cross-contamination: #E35205;
$fs-cross-contamination-bg: rgb(227 82 5 / 0.15);
$fs-pests: #5D3754;
$fs-pests-bg: rgb(153 72 120 / 0.15);
$fs-cleaning-sanitation: #FFB549;
$fs-cleaning-sanitation-bg: rgb(255 181 73 / 0.15);
$fs-health-hygiene: #3EB1C8;
$fs-health-hygiene-bg: rgb(62 177 200 / 0.15);
$fs-informational-only: #004F71;
$fs-informational-only-bg: rgb(0 79 113 / 0.15);

/* Colors for Historical Action Cards */
$ha-text: #081f32;
$ha-timestamp: #656565;
$ha-line: #a9acb7;

/* Colours for Amendment Badges */
$badge-tag-bg-white-blue: #d9e5ea;
$badge-tag-findings-dark-orange: #ad601e;
$badge-tag-findings-white-orange: #f3e7dd;
$badge-tag-denied-white-red: #f3dfe0;
$badge-tag-approved-white-green: #def1e9;

/* smart shop findings badges */
$ss-clean-and-safe: rgba(227 82 5 / 1);
$ss-clean-and-safe-bg: rgba(227 82 5 / 0.15);
$ss-fast-and-accurate: rgba(153 72 120 / 1);
$ss-fast-and-accurate-bg: rgba(153 72 120 / 0.15);
$ss-great-food: rgba(62 177  200 / 1);
$ss-great-food-bg: rgba(62 177  200 / 0.15);
$ss-compliant: rgba(0 99 91 / 1);
$ss-compliant-bg: rgba(0 99 91 / 0.15);
$ss-noncompliant: rgba(221 0 49 / 1);
$ss-noncompliant-bg: rgba(221 0 49 / 0.15);
$ss-date: rgba(0 107 154 / 1);
$ss-date-bg: rgba(0 107 154 / 0.15);
$ss-order-channel: rgba(91 103 112 / 1);
$ss-order-channel-bg: rgba(91 103 112  / 0.15);

// App variables

.danger {
  color: $primary-dark;
}

.warning {
  color: $honey-mustard;
}

.info {
  color: $navy
}


// TODO 
// :root {
//   --cfa-blue-dark: #004e71;
// }