@import '../../../../../colors';

.multiple-choice-question {
	&__form {
		display: flex;
		flex-direction: column;
		gap: .5rem;
	}
}

@media (width <= 475px) {
  .cfa-dropdown-display {
    text-align: left;
  }
  
  .cfa-dropdown-list {
    li:nth-of-type(even) {
      background-color: #004f710d;
    }

    .cfa-dropdown-item {
      height: auto;
      line-height: 1.5;
      padding: 0.5rem 1rem;
    }
  }

  .cfa-checkbox-label {
    align-items: flex-start;
  }
}





