@import "../../../App";
@import "../../../colors";

.appeal-badges {
    &__container {
        @media (width <=710px) {
            margin-bottom: 0.5em;
            width: 100%;
        }
    }

    &__tag {
        border-radius: 1px;
        padding: 0.5em;
        font-size: 0.75rem;
        margin: 0 1.5em 0 0;
    }

    &__default-colors {
        background-color: $badge-tag-bg-white-blue;
        color: $navy;
    }

    &__approved {
        background: $ss-compliant-bg;
        color: $ss-compliant;
        
    }
    
    &__removed {
        background: $ss-compliant-bg;
        color: $ss-compliant;
        
    }
    
    &__in-review {
        background: $fs-low-bg;
        color: $fs-low;
        
    }
    
    &__modified {
        background: $ss-compliant-bg;
        color: $ss-compliant;
        
    }
    
    &__upheld {
        background: $ss-order-channel-bg;
        color: $ss-order-channel;
        
    }
}