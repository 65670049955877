@import '../../../colors';

.modify-appeal-modal {
    @media (width <=992px) {
        width: 90%;
    }

    &__header-title {
        color: $cfa-black;
        font-weight: bold;
    }

    &__header-sub-title {
        font-size: 14px;
        margin: 0.5em 0 2em;
    }

    &__section-container {
        display: flex;
        flex-wrap: wrap;

        &--short-space {
            margin-bottom: -1.1em
        }
    }



    &__section-title {
        margin-right: 1.75em;
        font-family: 'Apercu';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        width: 8em;
    }

    &__section-text {
        color: $cfa-black;
        font-family: 'Apercu';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
    }

    &__cancel-btn {
        letter-spacing: 0.05em;
        background-color: transparent;
        color: $cfa-blue-dark;
        border-color: transparent;
        font-weight: bold;
        padding-right: 0;
        margin: 0.1em 2em 0 0;
    }

    &__dropdown-findings-title {
        margin: 1em 0 1.2em;
        font-family: 'Apercu';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: $cfa-gray;
    }

    &__select-dropdown {
        border-radius: 8px;
    }

    &__text-ipunt-title {
        margin: 2em 0 0;
        font-family: 'Apercu';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: $cfa-gray;
    }

    &__charcount {
        display: flex;
        justify-content: end;
        font-size: 0.75em;
        margin: 0;
    }

    &__textbox {
        width: 100%;
        resize: none;
        padding: 0.75em;
    }

    &__btn-container {
        display: flex;
        justify-content: end;
        margin: 2em 0 -0.521em;
    }
}