@import "../../../App";

.amendments-card {
    &__container {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 1em 2.5em 1em 1.5em;
        margin-bottom: 2em;
        margin-top: 1em;
        background: $white;
        box-shadow: 0 7px 20px rgb(0 0 0 / 0.16);
        border-radius: 8px !important;
    }

    &__header-container {
        display: flex;
    }

    &__tag-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0.5em 0 0;
    }

    &__tag-title {
        font-weight: bold;
        font-size: 0.875em;
        color: $cfa-black;
        margin: 0 0.369em 0 0;
    }

    &__tag-data {
        font-size: 0.875em;
        color: $cfa-black;
        margin: 0;
    }

    &__tag-header {
        display: flex;
        flex-wrap: wrap;
    }

    &__finding-title {
        margin-top: 0.71em;
        margin-bottom: 0.25em;
        color: $cfa-black;
        font-weight: bold;
        font-size: 1.5rem;
    }

    &__store-title {
        margin-top: 0.25em;
        margin-bottom: 0;
        color: $cfa-black;
        font-weight: bold;
        font-size: 1.125rem;
    }

    &__actions-buttons-container {
        display: flex;
        align-items: center;
        gap: .5rem;

        @media (width <=992px) {
          flex: 1;
        }

        @media (width <=710px) {
          flex-direction: column;
        }

        &--comment-btns {
          gap: 2rem;
        }
    }

    &__flex-button {
        display: flex;
        justify-content: center;
        align-items: center;
        @media (width <=992px) {
          flex: 1;

          button {
            min-width: 0 !important;
          }
        }
    }

    &__download-btn-container {
      flex: 1;
      justify-content: flex-end;
      order: 1;

      @media (width <=992px) {
        order: 0;
        flex: 1 1 100%;
        margin-bottom: 1rem;
      }
    }

    &__download-btn {
      height: auto;
      padding: 0 .5rem;
      min-width: auto;
    }

    &__buttons-divider {
        border-top: 0.15em solid $amendments-button-divider;
        margin-top: 1em;
        margin-bottom: 1em;
    }

    &__actions-buttons-approve {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__actions-buttons-approve:hover {
        background-color: rgba($color: $cfa-success-green, $alpha: 0.5);
    }

    &__actions-buttons-noHover {
        pointer-events: none;
    }

    &__actions-btn {
        background-color: transparent;
        border-color: transparent;

        & > {
            vertical-align: inherit;
        }

        &--approve {
          border-color: $cfa-success-green !important;
        }
    }

    &__actions-btn-deny-text {
        color: $cfa-red-dark !important;
        letter-spacing: 0.05em;
    }

    &__actions-btn-approve-text {
        color: $cfa-success-green !important;
        letter-spacing: 0.05em;
    }

    &__details-info {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @media (width >= 711px) {
            max-width: 85%;
        }

        @media (width <= 710px) {
            max-width: 100%;
        }

    }

    &__details-info-container {
        margin-top: 1em;
        margin-right: 2em;

        @media (width <=710px) {
            max-width: 100%;
        }
    }

    &__requester-info-container {
        display: flex;
    }

    &__requester-title {
        font-size: 0.875rem;
        font-weight: bold;
    }

    &__requester-name {
        font-size: 0.875rem;
        margin-left: 0.5em;
    }

    &__details-attachment-container {
        margin-right: 2em;
        max-width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    &__details-attachment-link {
        padding-right: 1em;
        padding-bottom: 0.5em;
        color: $cfa-black;
        width: max-content;
        text-decoration: underline;
    }

    &__details-info-title {
        font-size: 1rem;
        font-weight: bold;
        line-height: 20px;
        color: $dark-gray;
    }

    &__details-info-data {
        color: $cfa-black;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.25em;
        word-wrap: break-word;
        width: 100%;
        overflow-wrap: anywhere;
    }

    &__comments-findings-btns-container {
        order: 8;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-flow: row wrap;
        gap: 1rem;
    }

    &__open-findings-box-btn {
        display: flex;
        letter-spacing: 0.05em;
        background-color: transparent;
        color: $cfa-blue-dark;
        border-color: transparent;
        font-weight: bold;
        padding-left: 0;
    }

    &__open-comments-box-btn {
        letter-spacing: 0.05em;
        padding: 0 !important;
        min-width: 0 !important;
        border-radius: 0 !important;

        &--disabled {
            color: $faded-dark-blue;
        }
    }

    &__modify-btn {
        letter-spacing: 0.05em;
    }

    &__icon {
      path:not(mask path) {
        fill: none;
      }

      &--deny {
        color: $cfa-red-dark;
      }

      &--approve {
        color: $cfa-success-green;
      }
    }
}