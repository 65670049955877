.lvr-form {
  align-content: center;
  align-items: center;

	&__button-group {
		display: flex;
		justify-content: flex-end;
		gap: 1rem;

		&__button {
			margin: 1rem 0 1.5rem;
		}
	}
}
