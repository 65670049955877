@import "../../../App";

.amendment-approve-modal {
    background-color: $white;
    width: 42.875em;
    max-width: 100%;
    padding: 1.5em 1.75em;
    border-radius: 0.5em;
    box-shadow: 0 2px 4px rgb(0 0 0 / 0.2);

    @media (width <=992px) {
      width: 90%;
    }

    &__container {
      background-color: rgba($color: $cfa-black, $alpha: 0.2);
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      z-index: 100;
    }

    &__title-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__title {
      color: $cfa-black;
      font-size: 1.125rem;
      font-weight: bold;
      margin: 0;
    }

    &__finding-title {
      color: $cfa-black;
      font-size: 1rem;
      font-weight: bold;
      max-width: 85%;
      margin: 0;
    }

    &__subtitle {
      color: $cfa-black;
      font-size: 1rem;
      margin: 1em 0;
    }

    &__text {
      color: $cfa-black;
      font-size: 1rem;
      margin: 0 0 0.75em;
    }

    &__detail-container {
      display: flex;
    }

    &__detail-title {
      color: $cfa-black;
      font-weight: bold;
      margin: 0 0.25em 0 0;
    }

    &__detail-text {
      color: $cfa-black;
      margin: 0;
    }

    &__textbox {
      width: 100%;
      resize: none;
      padding: 0.75em;
    }

    &__charcount {
      display: flex;
      justify-content: end;
      font-size: 0.75em;
      margin: 0;
    }

    &__buttons-container {
      display: flex;
      justify-content: end;
      margin: 1em 0 0;
    }

    &__cancel-button {
      color: $navy;
      letter-spacing: 0.1em;
      font-weight: bold;
      background-color: $white;
      border: none;
    }

    &__done-button {
      width: 12.5em;
      height: 3em;
      background: $navy;
      border-radius: 15em;
      border: none;
      letter-spacing: 0.1em;
      font-weight: bold;
      color: $white;
      margin-left: 1em;
    }

    &__done-button-disabled {
      width: 12.5em;
      height: 3em;
      background: $amendments-button-divider;
      border-radius: 15em;
      border: none;
      letter-spacing: 0.1em;
      font-weight: bold;
      color: $done-button-disabled;
      margin-left: 1em;
    }
}