@import '../../../../../colors';

.free-response-question {
    margin-bottom: 1em;

		&__textarea .cfa-textfield {
			padding: 12px;
		}

		&__textarea textarea {
			min-height: 3.125rem; // prevent user from reducing height below default row size
		}


		&__image-uploader-subtitle {
			text-align: center;
			color: $default
		}

		&__image-uploader-container {
			margin: 3rem 0;
		}
}
