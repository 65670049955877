@import "../../../../../colors";

.cfa-accordion-item-header {
  &:hover,
  &:focus {
    background-color: white;
  }
}

.lvr-gen-info {
  * {
    font-family: "Apercu";
  }

  &__accordion-title {
    border: none !important;
  }

  &__title {
    font-size: 1.5rem;
  }

  &__row {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 1rem;
    @media (width <= 710px) {
      flex-direction: column;
      width: auto;
    }
  }

  &__row_group {
    width: 100%;
    display: flex;
    gap: 1rem;
    @media (width <= 710px) {
      gap: 0;
      flex-direction: column;
      width: auto;
    }
  }

  &__flex1 {
    flex: 1
  }

  &__subtitle {
    color: $cfa-gray;
    font-size: 0.875rem;
  }

  &__question {
    color: $cfa-gray;
  }

  &__link {
    text-decoration: underline;
  }

  &__input {
    margin: .5rem 0;
  }

  &__input-picker {
    margin: 0 0 1.25rem
  }

  @media (width <= 710px) {
    &__row {
      flex-direction: column;
      width: auto;
    }
  }
}

