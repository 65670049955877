.finding-overview-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  &__badges {
    display: flex;
    flex-wrap: wrap;
  }
  
  &__answer-container {
    min-height: 4rem;
  }
  
}

