@import "../../../../../App";

.selected-finding {
  text-align: left;
  // margin: 1rem 0;
  @media (width >= 711px) {
    padding: 1rem 3rem;
  }
  @media (width <= 710px) {
    padding: 1rem 0.75em;
  }
}

.image-gallery {
  min-width: 15em;
  min-height: 7em;
  max-width: 30em;
  max-height: 30em;
  margin: 0.7em 1em 0.7em 0;
}

:not(.fullscreen) > .image-gallery-slide-wrapper {
  .image-gallery-index {
    font-size: 12px;
  }

  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 30px;
    width: 15px;
  }

  .image-gallery-fullscreen-button {
    padding: 10px;

    .image-gallery-svg {
      height: 18px;
      width: 18px;
    }
  }
}
