@import "../../../../App";
@import "../../../../colors";

.food-label {
  &__container {
    border-radius: 0.06em;
    display: flex;
    justify-content: space-around;
    overflow: hidden;
    align-items: center;
    width: fit-content;

    &__normal {
      padding: 0.1em 0.5em;
      max-height: 1.25em;
    }

    &__large {
      @media (width >= 711px) {
        max-height: 3em;
        min-width: 10em;
      }
      @media (width <= 710px) {
        max-height: 2em;
        min-width: 6em;
      }
    }
  }

  &__needs-margin {
    margin-right: 1em;
    margin-bottom: 0.75em;
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    color: $cfa-black;
    margin-bottom: 0;
    text-align: center;
    align-items: center;
    white-space: nowrap;

    &__normal {
      font-size: 0.75rem;
    }

    &__large {
      @media (width >= 711px) {
        font-size: 1rem;
      }
      @media (width <= 710px) {
        font-size: 0.7rem;
      }
    }
  }

  &__img {
    float: left;
    margin-right: 0.25em;
  }

  &__round {
    background: $fs-default-bg;
  }

  &__round__title {
    color: $fs-default;
  }

  &__repeat {
    margin: 0.5em 0 0;
    background: $fs-default-bg;
  }

  &__repeat__title {
    color: $fs-default;
  }

  &__immediate {
    background: $fs-immediate-bg;

    &__title {
      color: $fs-immediate;
    }
  }

  &__high {
    background: $fs-high-bg;

    &__title {
      color: $fs-high;
    }
  }

  &__medium {
    background: $fs-medium-bg;

    &__title {
      color: $fs-medium;
    }
  }

  &__low {
    background: $fs-low-bg;

    &__title {
      color: $fs-low;
    }
  }

  &__informational {
    background: $fs-informational-bg;

    &__title {
      color: $fs-informational;
    }
  }

  &__time-temperature {
    background: $fs-time-temperature-bg;

    &__title {
      color: $fs-time-temperature;
    }
  }

  &__cross-contamination {
    background: $fs-cross-contamination-bg;

    &__title {
      color: $fs-cross-contamination;
    }
  }

  &__pests {
    background: $fs-pests-bg;

    &__title {
      color: $fs-pests;
    }
  }

  &__cleaning-sanitation {
    background: $fs-cleaning-sanitation-bg;

    &__title {
      color: $fs-cleaning-sanitation;
    }
  }

  &__great-food {
    background: $fs-medium-bg;

    &__title {
      color: $fs-medium;
    }
  }

  &__clean-safe-environment {
    background: $fs-health-hygiene-bg;

    &__title {
      color: $fs-health-hygiene;
    }
  }

  &__operator-consulting-notes {
    background: $light-purple;

    &__title {
      color: $time-temperature;
    }
  }

  &__staff {
    background: $fs-informational-bg;

    &__title {
      color: $fs-informational;
    }
  }

  &__guest-experience {
    background: $fs-low-bg;

    &__title {
      color: $fs-low;
    }
  }

  &__health-hygiene {
    background: $honey-mustard-lite;

    &__title {
      color: $honey-mustard;
    }
  }

  &__informational-only {
    background: $fs-informational-only-bg;

    &__title {
      color: $fs-informational-only;
      margin-top: 0.1em;
    }
  }

  &__general-information-smart-shop {
    background: $fs-low-bg;

    &__title {
      color: $fs-low;
    }
  }

  &__great-food-smart-shop {
    background: $ss-great-food-bg;

    &__title {
      color: $ss-great-food;
    }
  }

  &__genuine-hospitality-smart-shop {
    background: $light-green;

    &__title {
      color: $secondary-green;
    }
  }

  &__clean-and-safe-environment-smart-shop {
    background: $ss-clean-and-safe-bg;

    &__title {
      color: $ss-clean-and-safe;
    }
  }

  &__fast-accurate-smart-shop {
    background: $ss-fast-and-accurate-bg;

    &__title {
      color: $ss-fast-and-accurate;
    }
  }

  &__compliant-smart-shop {
    background: $ss-compliant-bg;

    &__title {
      color: $ss-compliant;
    }
  }

  &__noncompliant-smart-shop {
    background: $ss-noncompliant-bg;

    &__title {
      color: $ss-noncompliant;
    }
  }

  &__informational-smart-shop {
    background: $fs-informational-only-bg;

    &__title {
      color: $fs-informational-only;
    }
  }

  &__round-smart-shop {
    background: $ss-date-bg;

    &__title {
      color: $ss-date;
    }
  }

  &__order-channel-smart-shop {
    background: $ss-order-channel-bg;

    &__title {
      color: $ss-order-channel;
    }
  }

}
