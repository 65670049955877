@import "../../../../App";


// background-color: $honey-mustard
.SelfAssessment {
  background-color: $white-gray;
  max-width: 40rem;
  margin-top: 1rem;
  @media (width >= 992px) {
    max-width: 45rem;
  }

  .kn-multi_select__wrapper___30BEc,
  .multi-select-container {
    height: 15rem !important;
    width: 100%;
  }

  input .jss9 {
    font-size: 0.8rem;
  }

  .kn-item_label__label___2fJ5p {
    margin-top: 0.1rem;
    font-size: 0.8rem;
  }

  .kn-no_items__no_items___3ON4R {
    margin-top: -5rem;
  }

  .multi-select-input {
    font-size: 0.8rem;
    font-family: "Apercu-medium";
    color: $default;
    margin-bottom: -0.2rem;
  }

  .assess-title-container {
    margin: 0.25rem 0 0.75rem;
    padding-left: 3rem;
    justify-content: center;
    text-align: center;

    .assess-title-icon {
      text-align: center;
      margin: auto;
      justify-content: center;
    }

    .assess-title {
      margin: 1rem auto;
      font-size: 1.2em;
    }
  }

  .add-safe-question-card {
    //background-color: $link-blue;
    padding: 1rem 2rem;
    margin: 1rem auto;
    width: 100%;

    @media only screen and (width <= 404px) {
      width: 100%;
    }

    @media (width >= 405px) and (width <= 767px) {
      width: 80%;
    }

    @media (width >= 992px) {
      max-width: 45rem;
    }

    .input-row {
      margin: 0 0 2rem;

      @media only screen and (width <= 404px) {
        width: 100%;
      }

      @media (width >= 405px) and (width <= 767px) {
        width: 80%;
      }

      @media (width >= 992px) {
        width: 90%;
      }
    }

    .input-question-label {
      font-size: 0.8rem !important;
      font-family: "Apercu-medium";
      color: $dark-gray;
      visibility: hidden;
    }

    .input-label {
      font-size: 0.8rem !important;
      font-family: "Apercu-medium";
      color: $dark-gray;
    }

    .risk-level-label {
      font-size: 0.8rem !important;
      font-family: "Apercu-medium";
      color: $dark-gray;
      padding-right: 95px;
    }

    .risk-column {
      max-width: 132px;
      padding-left: 0;
    }

    .risk-dropdown-toggle {
      padding-left: 0;
    }

    .risk-level-btn-dropdown {
      width: 5px;
    }

    .risk-level-dropdown-menu {
      width: 5px;
    }


    .dd-item {
      padding-left: 0;
    }

    .category-input-label {
      font-size: 0.8rem !important;
      font-family: "Apercu-medium";
      color: $dark-gray;
      padding-right: 40px;
    }

    .category-column {
      max-width: 250px;
    }

    .category-dropdown-toggle {
      padding-left: 0;
      text-align: left;
      width: 150px;
    }

    .category-btn-dropdown {
      padding-left: 11px;
      width: 175px;
    }

    .category-dropdown-menu {
      padding-left: 11px;
      width: 175px
    }

    .effective-start-date {
      font-size: 0.8rem !important;
      font-family: "Apercu-medium";
      color: $dark-gray;
      padding-right: 3.2rem;
    }

    .effective-end-date {
      font-size: 0.8rem !important;
      font-family: "Apercu-medium";
      color: $dark-gray;
      padding-right: 3rem;
    }

    .dropdown-label-row {
      margin: 0;
    }

    .dropdowns-row {
      margin: 0 0 2rem;
      padding: 4px 8px 4px 0;
    }

    .question-id {
      max-width: 3500px;
      visibility: hidden;
    }

    .question-management-viewable-on-report-checkbox {
      padding-top: 2em;
      margin: .5em 0 0 3em;
    }

    .question-management-viewable-on-report-label {
      font-family: "Apercu";
      font-style: normal;
      font-weight: 500;
      font-size: 0.875em;
      color: #5b6770;
      margin: 0 0 0 0.3em;
    }
  }

  .desktop-back-link {
    font-size: 1rem;
    text-align: left;
    color: $dark-gray;
    cursor: pointer !important;
  }

  .desktop-back-arrow {
    margin-right: 0.1rem;
  }

  .safe-question-form {
      width: 100%;
  }

  .selection-error {
    font-size: 0.85rem;
    font-family: "Apercu-Medium";
    margin: 1rem auto -1rem;
    text-align: center;
    color: $primary-dark;
  }

  .error {
    font-size: 0.85rem;
    font-family: "Apercu-Medium";
    margin: -1rem auto 2.25rem;
    text-align: center;
    color: $primary-dark;
  }

  .submit-button {
    margin-top: -0.5rem;
    margin-bottom: 0.5rem;
    font-size: 0.85rem;
    font-weight: bold;
    align-self: center;
    height: 2.5rem;
    width: 10rem;
    color: $white;
    border-color: $navy;
    background-color: $navy;
    border-radius: 3rem;
  }

  .submit-button:hover {
    background-color: $secondary-purple;
    border: $white;
  }

  .submit-button:disabled {
    cursor: pointer;
    background-color: $light-gray;
    border-color: $light-gray;
  }

  .submit-button:disabled:hover {
    background-color: $light-gray;
  }

  .success-container {
    text-align: center;

    .success-icon {
      margin-top: 2rem;
    }

    h4 {
      color: $default;
      margin-top: 2rem;
    }

    .go-back-button {
      margin: 3rem auto 2rem;
      font-size: 0.85rem;
      font-weight: bold;
      align-self: center;
      height: 2.5rem;
      width: 13rem;
      color: $white;
      border-color: $navy;
      background-color: $navy;
      border-radius: 3rem;
    }

    .go-back-button:hover {
      background-color: $secondary-purple;
      border-color: $secondary-purple;
    }
  }
}

.quarter-select-container {
  width: 9em;
  text-align: left;
}

.quarter-dropdown {
  font-size: 1rem;
  color: $cfa-black;

  @media (width <= 575px) {
    width: 100%;
  }
}

.add-safe-question-form__start-round {
  margin-right: 3rem;
  z-index: 2;
}

.add-safe-question-form__end-round {
  z-index: 2;
}

.add-safe-question-form__typeahead {
  width: 100%;
}

.add-safe-question-form__round-error {
  color: red;
  font-size: 1rem !important;
  font-family: "Apercu-medium";
  padding-top: 1rem;
  padding-left: 1rem;
  padding-bottom: 0;
}
