@import '../../../../colors';

.org-question {
  &__container {
    background-color: $white;
    max-width: 70em;
    padding: 2rem 7rem;
    width: 100%;
    @media (width <= 500px) {
      padding: 2rem 3rem;
    }
  }

  &__button-container {
    display: flex;
    padding-top: 3rem;
    justify-content: center;
    @media (width <= 500px) {
      flex-direction: column;
    }
  }

  &__no-button {
    margin-left: 1rem;
    @media (width <= 500px) {
      margin-top: 1rem;
      margin-left: 0;
    }
  }

  &__finding-id {
    font-weight: 600;
    color: $light-gray;
    padding-bottom: 1rem;
  }

  &__question-text {
    padding-top: 1rem;
  }
}