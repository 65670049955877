.total-findings-chart {
  &__graph-container {
    height: 390px;
    width: 80%;
    margin: 4em 0 0 6em;

    @media (width <= 820px) {
      width: 110%;
      margin: 3em 0 0 -1em;
    }

    // Break point
    @media (width <= 768px) {
      width: 110%;
      margin: 4em 0 0 -1em;
    }
  }

  &__left-axis-title {
    writing-mode: vertical-lr;
    transform: rotate(-180deg);
    position: absolute;
    top: 15em;
    left: 3rem;

    // Break point
    // iPad air
    @media (width <= 820px) {
      display: none;
    }
  }
}
