@import "../../../App";

.amendment-comment-container {
    background-color: $white;
    box-shadow: 0 7px 20px rgb(0 0 0 / 0.16);
    border-radius: 8px;
    width: 100%;
    margin-bottom: 2em;
    padding: 1.5em;
    max-height: 45em;

    &__comments {
        overflow: auto;
        max-height: 25em;
    }

    .comment_input--ready-only {
        background-color: $white-gray;
        opacity: 1;
        border: 1px solid $light-blue-gray;
        border-radius: 0.25em;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        color: $dark-gray;
    }
}

.amendment-comment {
    &__header-container {
        display: flex;
        order: 1;
        margin-bottom: 0;
        font-size: 1.25rem;
        font-weight: bold;
    }

    &__header-title {
        font-size: 17px;
        margin: 2em 0 -5em 2em;
        color: $cfa-black;
    }

    &__close-btn {
        display: flex;
        font-size: 18px;
        letter-spacing: 0.05em;
        color: $dark-gray;
        background-color: transparent;
        border-color: transparent;
        margin-left: auto;
    }

    &__comment_input {
        margin: 3em auto 1.5em;
        width: 100%;
        height: 6.25em;
        font-size: 0.875rem;
        font-weight: 400;
    }

    &__input-container {
        width: 100%;
    }

    &__user-signature {
        margin: 0 0 -0.4em 2.4em;
        color: $cfa-black;
        font-size: 16px;
    }

    &__edit {
        font-weight: bold;
        color: $cfa-blue-dark;
        cursor: pointer;
        margin-left: 1em;

        &::before {
            background: url("../../assets/icons/pencil.svg") no-repeat;
            background-size: contain;
            content: "";
            display: inline-block;
            width: 14px;
            height: 12px;
            margin-right: 0.4em;
        }
    }

    &__submit-btn {
        display: flex;
        letter-spacing: 0.05em;
        background-color: transparent;
        color: $cfa-blue-dark;
        border-color: transparent;
        font-weight: bold;
        margin-left: auto;
        order: 6;

        &--disabled {
            color: $faded-dark-blue;
        }
    }
}
