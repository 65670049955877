@import '../../../../../../colors';

.multiple-choice-question {
	margin: 0 0 1.25em;

	&__checkbox-wrapper {
		display: flex;

		.cfa-checkbox {
			flex: 1 0 auto;
		}
	}

	&__text {
			font-size: 0.875em;
			color: $default;
	}

	&__subquestion-container {
			padding-top: 1em
	}

	&__tooltip {
		margin-left: .25rem;

		svg {
			width: 0.875rem;
			height: 0.875rem;
			vertical-align: top;
		}
	}

	&__radio-group {
		fieldset {
			flex-flow: row wrap;
			gap: 1rem;

			> div {
				margin: 0 !important;
			}
		}

	}
}