@import '../../../../App';

.submission-card {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;

  &__title {
    color: #5b6770;
    flex-basis: 100%;
    text-align: center;
    margin-top: 24px;
  }

  &__finding {
    text-align: center;
    color: #5b6770;
  }

  &__button-container {
    margin-top: 3em;
    display: flex;
    justify-content: space-between;
  }

  &__button {
    margin: 1em 0.5em 0;
  }
}
