@import './App';
@import './colors';

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $white-gray  !important;
}

code {
  font-family: "source-code-pro", "Menlo", "Monaco", "Consolas", 'Courier New',
    monospace;
}

svg {
  vertical-align: baseline;
}


// Global styling

.fsd {
  &__page-container {
    background-color: #f5f5f5;
    margin: 3em auto 5em;
    max-width: 70em;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }

  &__links-label {
    font-style: normal;
    font-weight: bold;
    font-size: 1.125em;
    color: $cfa-black;
    margin-bottom: 0.5em;
  }

  &__links-url {
    font-family: "Apercu";
    font-weight: 500;
    font-size: 1rem;
    color: $cfa-blue-dark;
    margin-bottom: 0.75em;
  }

  &__links-icon {
    padding-right: 0.25em;
    padding-bottom: 0.15em;
  }

  &__links-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  &__links-list-item {
    padding: 0.5em 0;
  }
}