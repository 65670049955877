@import "../../../../App";
@import "../../../../colors";

.all-findings {
    background-color: $white-gray;
    max-width: 70em;

    @media (width >= 711px) {
        margin-top: 1em;
    }

    @media (width <= 710px) {
        margin-top: 1em;
        padding: 0 1em;
    }

    &__title-container {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        min-width: 22em;
        margin-bottom: 1em;
    }

    &__switch {
        margin-left: 1em;
    }

    &__header-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 1em;
        margin-bottom: 1em;
    }

    &__filter-container {
        display: flex;
        flex-wrap: wrap;
        gap: 1em;
        align-items: flex-end;
    }

    &__findings-count {
        font-family: "Apercu";
        font-style: italic;
        font-weight: 400;
        font-size: 14px;
        color: #5b6770;
        margin-bottom: 0;
    }

    &__no-results {
        margin: 2em 0;
        text-align: center;
    }

    &__findings-container {
        .finding-card {
            margin: 0 0 1em;
        }
    }

    &__filter-btn {
        min-width: 6.5em !important;
    }

    &__sort-dropdown {
        @media (width <= 710px) {
            flex-basis: 100%;
        }

        .cfa-dropdown {
            width: 10em !important;
        }
    }
}
