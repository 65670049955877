@import '../../../colors';

.pathway-card {
    &__container {
        padding: 2em 3em;
        border-radius: 8px;
        max-width: 39em;
        min-height: 11em;
        margin: 4em 0 0;
        box-shadow: 0 7px 20px rgb(0 0 0 / 0.16);
    }

    &__links-container {
        margin-top: 2em;
        display: flex;
        flex-direction: column;
    }

    &__question-text {
        color: $cfa-black;
        text-align: center;
        font-weight: bold;
        margin: 0 2em;
    }
}