@import "../../../App";

.audit-selector-form {
	// !important is required to override tag styles set on line 187 of src/app/pages/ReportDashboard/FoodSafetyReport/FoodSafetyReport.scss
	span {
		color: $dark-gray !important;
		font-family: inherit !important;
	}

	.cfa-dropdown-placeholder {
		color: #a7acaf !important;
	}
}
