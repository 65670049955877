@import '../../../../colors';
 
.smart-shop-inquiry-form {
  &__container {
    margin: 3rem auto 5rem;
    max-width: 70rem;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
  }

  &__page-loading-indicator {
    display: flex;
    padding-top: 20rem;
    justify-content: center;
  }

  &__breadcrumbs {
    padding-bottom: 2rem;
  }

  &__content {
    padding: .4rem;
  }

  &__date {
    font-weight: 600;
    color: $light-gray;
  }
}