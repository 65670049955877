
.all-findings {
  &__header {
    margin-bottom: 2rem;
  }

  &__dropdown-n-filter-btn-container {
    gap: 1rem;
    display: flex;
    align-items: end;
    justify-content: end;

    @media (width <= 610px) {
      align-items: start;
      flex-direction: column;
    }
  }

  &__dropdown {
    width: 12em !important;

    .cfa-dropdown-display {
      white-space: nowrap;
    }
  }

  &__filter-btn {
    height: 2.5rem;
    min-width: 6.5em !important;
  }

  &__no-results {
    padding-top: 3rem;
  }

  @media (width <= 910px) {
    flex-direction: column;
  }

}
