@import '../../../../colors';

.request-appeal {
  &__container {
    background-color: $white;
    max-width: 70em;
    padding: 2rem 3rem;
    width: 100%;
  }

  &__button-container {
    display: flex;
    padding-top: 3rem;
    justify-content: end;
    @media (width <= 500px) {
      padding-top: 1rem;
      flex-direction: column;
    }
  }

  &__file-uploader-container {
    padding-top: 3rem;
  }

  &__finding-id {
    padding-bottom: 2rem;
  }

  &__link-text {
    padding-top: 1rem;
  }

  &__optional_text {
    padding-left: .1rem;
    color: $light-gray;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
  }

  &__upload-label-text {
    color: $cfa-gray;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
  }

  &__browse_text {
    padding-left: .3rem;
    color: $link-blue;
    text-decoration: underline;
  }

  &__file-upload-content {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
  }

  &__label-star {
    color: red;
  }
}