@import '../../../../colors';
 
.admin-amendment-form {
  &__container {
    margin: 3rem auto 5rem;
    max-width: 70rem;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
  }

  &__body-container {
    background-color: $white;
    max-width: 70em;
    padding: 2rem;
    width: 100%;
  }

  &__multiselect-container {
    margin: 1rem .5rem;
  }

  &__breadcrumbs {
    padding-bottom: 2rem;
  }

  &__selector_container {
    padding-top: 1rem;
  }

  &__button-container {
    display: flex;
    padding-top: 3rem;
    justify-content: end;
    @media (width <= 500px) {
      padding-top: 0;
      flex-direction: column;
    }
  }

  &__store-selector {
    margin-top: 1.5rem;
    margin-bottom: .5rem;
  }

  &__text-field {
    margin-top: 2rem;
  }

  &__next-button {
    margin-left: 1rem;
    @media (width <= 500px) {
      margin-left: 0;
      margin-top: 1rem;
    }
  }
}