@import "../../../App";

.amendment-badges {
    &__container {
        @media (width <= 710px) {
            margin-bottom: 0.5em;
            width: 100%;
        }
    }

    &__tag {
        border-radius: 1px;
        padding: 0.5em;
        background-color: $badge-tag-bg-white-blue;
        color: $navy;
        font-size: 0.75rem;
        margin: 0 1.5em 0 0;
    }

    &__approved {
        background-color: $badge-tag-approved-white-green;
        color: $success;
    }

    &__denied {
        background-color: rgb(167 172 175 / 0.3);
        color: $default;
    }

    &__pending {
        background-color: rgb(255 181 73 / 0.15);
        color: $honey-mustard;
    }

    &__findings {
        background: $badge-tag-findings-white-orange;
        color: $badge-tag-findings-dark-orange;
    }
}
