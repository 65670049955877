@import "../../../../../colors";

.selected-finding {
  margin: .5rem;

  &__card-content{
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &__photo-container{
    display: flex;
    padding-top: 2rem;
    flex-direction: column;
  }

  &__answer-container{
    display: flex;
    padding-top: 2rem;
  }

  &__card-container {
    display: flex;
    flex-direction: column;
  }

  &__badge-container {
    display: flex;
  }

  &__badge-label {
    color: black;
    width: 7rem;
  }

  &__content {
    border: 1px solid #CCCFD0;
    box-shadow: 2px 4px #5B67703D;
  }

  &__response-container{
    padding-left: 5rem;
  }

  &__issue-text-container{
    padding-top: 4rem;
  }

  &__image{
    margin-right: 1rem;
    max-width: 15rem;
    max-height: 10rem;
  }

  &__issue-text-list{
    margin-left: -1.5rem;
  }
}