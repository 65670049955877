@import "../../../../../colors";

.past-findings {
  margin-top: 2rem;
  border: 1px solid #CCCFD0;
  box-shadow: 2px 4px #5B67703D;

  &__no-data-msg {
    text-align: center;
  }

  &__label-container{
    display: flex;
  }

  &__label {
    min-width: 10rem;
    color: $cfa-gray;
  }

  &__values-container {
    display: flex;
    margin-top: 1rem;
  }

  &__badge-container {
    min-width: 8.5rem;
  }
}