.top-focus-area {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &__action-buttons {
    display: flex;
    margin-top: 0.5rem;
    align-items: center;
    justify-content: space-between;
  }
}

.top-focus-area-no-data {
  height: 100%;
  min-height: 22.6rem;
  overflow: visible;
  text-align: center;
  justify-content: center;
}
