@import '../../../../colors';

.org-link {
  &__container {
    background-color: $white;
    max-width: 70em;
    padding: 2rem 8rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (width <= 500px) {
      padding: 2rem 3rem;
    }
  }

  &__button-container {
    display: flex;
    padding-top: 3rem;
    justify-content: center;
  }

  &__finding-id {
    font-weight: 600;
    color: $light-gray;
    padding-bottom: 1rem;
  }

  &__link-text {
    padding-top: 1rem;
  }
}