@import "../../../App";

.appeal-modal {
  background-color: $white;
  width: 55%;
  max-width: 100%;
  padding: 1.5em 1.75em;
  border-radius: 0.5em;
  box-shadow: 0 2px 4px rgb(0 0 0 / 0.2);

  @media (width <=992px) {
    width: 90%;
  }

  &__container {
    background-color: rgba($color: $cfa-black, $alpha: 0.2);
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 100;
  }

  &__controls {
    display: flex;
    justify-content: flex-end;
  }

  &__close-button {
    color: $navy;
    letter-spacing: 0.1em;
    font-weight: bold;
    background-color: $white;
    border: none;
  }

  &__title {
    color: $cfa-black;
    font-size: 1.125rem;
    font-weight: bold;
    margin: 0;
    text-align: center;
  }

  &__subtitle {
    color: $cfa-black;
    font-size: 1rem;
    margin: 1em 0;
    text-align: center;
  }

  &__info-container {
    margin-bottom: 1rem;
  }

  &__section-container {
    display: flex;
    align-items: flex-start;

    &--short-space {
      margin-bottom: -1.1em
    }
  }

  &__section-title {
    color: $cfa-black;
    margin-right: 1.75em;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 24px;
    min-width: 8em;
  }

  &__section-text {
    color: $cfa-black;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 20px;
  }

  &__text {
    color: $cfa-black;
    font-size: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 0;
  }

  &__reason {
    margin-top: 1rem;
    padding: 0;
    font-weight: bold;
  }

  &__textbox {
    width: 100%;
    resize: none;
    padding: 0.75em;
  }

  &__charcount {
    display: flex;
    justify-content: start;
    font-size: 0.75em;
    margin: 0;
  }

  &__buttons-container {
    display: flex;
    justify-content: center;
    margin: 1em 0 0;
  }

  &__button {
    width: 12.5em;
    height: 3em;
    border-radius: 15em;
    border-color: none;
    letter-spacing: 0.1em;
    font-weight: bold;
    color: $white;
    margin: 1em;
  }

  &__cancel-button {
    color: $navy;
    border-color: $navy;
    background-color: $white;
  }

  &__done-button {
    background: $navy;
  }

  &__done-button-disabled {
    background: $amendments-button-divider;
    color: $done-button-disabled;
  }
}