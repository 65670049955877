.beta {
  width: 4rem;
  justify-content: center;
  display: flex;
  align-items: center;
  

  &__badge-text {
    background-color: red;
    margin-bottom: 8px;
    border-radius: 25px;
    width: 2.5rem;
    text-align: center;
  }
}