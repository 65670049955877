@import '../../../../App';

.SafeQuestion {
  background-color: $white-gray;
  max-width: 50em;
  margin-top: 1rem;
  justify-self: start;

  .both-containers {
    width: 50em;
  }

  // @media (min-width: 992px) {
  //   max-width: 45rem;
  // }

  h2 {
    // margin-top: 1rem;
    // font-size: 1.55rem;
    // font-family: Apercu-Regular;
    // text-align: left;

    color: #2d2d2d;
    margin-bottom: 0.5 em;

    /* font-size: 36px; */
    margin: 0.75em 0 0.5em;
  }

  .question-card-container {
    margin: 1.5rem 0 0 1rem;
    width: 100%;
    background-color: $white-gray;
  }

  .app-card {
    margin: 0 auto;
    padding: 1.5rem 1.25em;
    height: 11rem;
    width: 15.5rem;

    h3 {
      font-size: 1.3rem;
      font-family: 'Apercu-Medium';
      color: $navy;
    }

    .app-card-icon {
      margin-top: 1.5rem;
      height: 3.25rem;
      width: 3.5rem;
      align-self: flex-end;
    }

    .report-icon {
      height: 3rem;
    }
  }

  .app-card:hover {
    text-decoration: underline;
    text-decoration-color: $dark-gray;
    cursor: pointer;
  }

  .view-question {
    float: right;
    position: relative;
    top: 7px;
    color: $navy;
    font-size: 0.8rem;
    text-decoration: no-underline !important;
    letter-spacing: 0.1em;
    background-color: transparent;
    border-color: transparent;
    font-weight: 700;
  }

  .risk-level-immediate-action {
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    font-weight: bold;
    // padding-left: 40px;
    // padding-right: 40px;
    align-self: right;
    max-width: 15rem;
    color: $white;
    border-color: $immediate;
    background-color: $immediate;
    // border-radius: 3rem;
    padding: 0.65em;
  }

  .risk-level-high {
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    font-weight: bold;
    // padding-left: 40px;
    // padding-right: 40px;
    align-self: right;
    max-width: 15rem;
    color: $white;
    border-color: $high;
    background-color: $high;
    // border-radius: 3rem;
    padding: 0.65em;
  }

  .risk-level-medium {
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    font-weight: bold;
    // padding-left: 40px;
    // padding-right: 40px;
    align-self: right;
    max-width: 15rem;
    color: $white;
    border-color: $medium;
    background-color: $medium;
    // border-radius: 3rem;
    padding: 0.65em;
  }

  .risk-level-low {
    align-items: center;
    justify-content: center;
    font-size: 0.85rem;
    font-weight: bold;
    // padding-left: 40px;
    // padding-right: 40px;
    align-self: right;
    max-width: 15rem;
    color: $white;
    border-color: $low;
    background-color: $low;
    // border-radius: 3rem;
    padding: 0.65em;
  }

  .risk-level-informational {
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    font-weight: bold;
    // padding-left: 40px;
    // padding-right: 40px;
    align-self: right;
    max-width: 15rem;
    color: $white;
    border-color: $informational;
    background-color: $informational;
    // border-radius: 3rem;
    padding: 0.65em;
  }

  .category-name-cleaning-sanitation {
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    font-weight: bold;
    margin-left: 10px;
    // padding-left: 40px;
    // padding-right: 40px;
    align-self: right;
    max-width: 15rem;
    color: $white;
    border-color: $cleaning-sanitation;
    background-color: $cleaning-sanitation;
    // border-radius: 3rem;
    padding: 0.65em;
  }

  .category-name-cross-contamination {
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    font-weight: bold;
    margin-left: 10px;
    // padding-left: 40px;
    // padding-right: 40px;
    align-self: right;
    max-width: 15rem;
    color: $white;
    border-color: $cross-contamiination;
    background-color: $cross-contamiination;
    // border-radius: 3rem;
    padding: 0.65em;
  }

  .category-name-health-hygiene {
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
    font-weight: bold;
    margin-left: 10px;
    // padding-left: 40px;
    // padding-right: 40px;
    align-self: right;
    max-width: 15rem;
    color: $white;
    border-color: $health-hygiene;
    background-color: $health-hygiene;
    // border-radius: 3rem;
    padding: 0.65em;
  }

  .category-name-pests {
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    font-weight: bold;
    margin-left: 10px;
    // padding-left: 40px;
    // padding-right: 40px;
    align-self: right;
    max-width: 15rem;
    color: $white;
    border-color: $pests;
    background-color: $pests;
    // border-radius: 3rem;
    padding: 0.65em;
  }

  .category-time-temperature {
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    font-weight: bold;
    margin-left: 10px;
    padding-left: 40px;
    padding-right: 40px;
    align-self: right;
    max-width: 15rem;
    color: $white;
    border-color: $time-temperature;
    background-color: $time-temperature;
    // border-radius: 3rem;
    padding: 0.65em;
  }
}

.finding-item {
  z-index: 10;
  padding: 1.1em 0.5em;
  background-color: $white;
  border-radius: 6px !important;
  margin-bottom: 2em;
  .finding-type {
    font-size: 0.8rem;
    font-family: 'Apercu-bold';
    text-transform: uppercase;
  }

  .finding-category {
    font-size: 0.8rem;
    font-family: 'Apercu-bold';
  }

  .question-report-id {
    font-weight: bold;
    margin-right: 0.2rem;
  }

  .question-message {
    // font-size: 0.85rem;
    font-size: 0.9rem;
    color: #3f3f3f;
    margin-bottom: 1.75em;
    letter-spacing: 0.01em;
    font-weight: 300;
  }
}

.finding-detail {
  padding: 0.5rem;
  justify-content: flex-end;

  img {
    margin-top: 0.05rem;
  }

  span {
    padding-left: 0.2rem;
    font-size: 0.8rem;
    color: $dark-gray;
    font-family: 'Apercu-medium';
  }

  span.past {
    color: $navy;
  }

  span.new-info {
    color: $teal;
  }
}

.finding-row {
  margin-right: -3rem;
  justify-content: space-between;

  .finding-link-col {
    text-align: right;
    align-content: center;
    padding-right: 0;
  }
}

.question-search-box {
  margin-right: -3rem;
  justify-content: space-between;
  // width: 48.2em;
  box-sizing: border-box;
  padding: 0.31em 1em;
  width: 100%;
}

.safe-question__quarter-selector {
  z-index: 11;
  padding-left: 1em;
}

.safe-question__quarter-selector-prompt {
  padding-top: 1em;
  padding-left: 1em;
  color: #2d2d2d;
  margin-bottom: 0.5em;
}
