@import '../../../../../../colors';

.performance-level-legend {
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 3rem;
	left: 2.75rem;
	z-index: 1;

	&__level-container {	
		display: flex;
		flex-direction: column;
		padding: .275rem;
		border-radius: 1rem;
		margin-bottom: .875rem;
		cursor: default;

		&--elite {
			background-color: $secondary-green;
		}

		&--good {
			background-color: $cfa-green-dark;
		}

		&--fair {
			background-color: $honey-mustard;
		}

		&--unsatisfactory {
			background-color: $fs-medium;
		}

		&--sub-standard {
			background-color: $primary;
		}

		.cfa-typography {
			line-height: .75rem;
		}
	}
}