@import "../../../../../colors";

.selected-finding {
  margin: .5rem;

  &__answer-container{
    margin-top: 1rem;
  }

  &__finding-details-label {
    color: $cfa-gray;
  }

  &__card-container {
    display: flex;
    flex-direction: column;
  }

  &__badge-container {
    display: flex;
  }

  &__badge-label {
    color: $cfa-gray;
    width: 7rem;
  }

  &__content {
    border: 1px solid #CCCFD0;
    box-shadow: 2px 4px #5B67703D;
  }
}