@import '../../../colors';

#historical-trends-card {
  width: 100%;
  min-height: 35em;

  #y-axis-legends {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 45px;
    padding-bottom: 64px;

    .dot {
      height: 20px;
      width: 20px;
      border-radius: 100%;
      display: inline-block;
      display: flex;
      justify-content: center;
    }
  }

  .card-title {
    font-weight: 700;
    line-height: 20px;
    color: #1E272F;
    margin: 1em 0 0 1em;
  }

  .chart-container {
    height: 361px;
    width: 80%;
  }
}


.graph-tooltipe-container {
  border: solid #f0eded 1px;
  border-radius: 3px;
  background-color: white;
  box-shadow: -9px -9px 9px #f7f7f7, 9px 9px 9px #e1e4ed;
  height: inherit;
  z-index: 20000;

  .circle-shape {
    height: 14px;
    width: 14px;
    display: inline-block;
    margin-right: 5px;
    border-radius: 50%;
  }

  .tooltip-text {
    font-weight: 300;
    margin-top: 0;
    padding: 0.7em 0.5em 0;
  }
}

.performance-level-graph {
  &__container {
    position: relative;
    display: flex;
    height: 80%;
    min-height: 25em;
    margin-top: 4.5em;
    justify-content: flex-end;

    @media (width <= 768px) {
      margin-top: 2.5em;
    }
  }
}