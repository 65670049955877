@import "../../../../App";

.additional-support-card {
    background: #fff;
    border-radius: 8px;
    margin-bottom: 3em;
    padding: 1.5em 1.5em 3em;
  
    &__element-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  
    &__element {
      padding: 1em 0;
      @media (width >= 711px) {
        max-width: 45%;
      }
    }
  
    &__element-url {
      font-family: "Apercu";
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      color: #004f71;
    }
  
    &__element-list {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
  
    &__element-list-item {
      padding: 0.5em 0;
    }
  
    &__element-links-icon {
      padding-right: 0.25em;
      padding-bottom: 0.15em;
    }
  }