@import '../../../../colors';

.finding-occurrence {
    &__container {
        display: flex;
        flex-wrap: wrap;
    }

    &__title {
        color: $cfa-black;
        font-size: 18px;
        font-weight: bold;
    }

    &__sub-title {
        font-size: 15px;
    }

}