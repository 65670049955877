@import '../../../../App';

.admin-appeal-form {
  display: flex;
  justify-content: center;
  flex-flow: column;

  &__flex-item {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  &__header-arrow {
    margin: 0 0.75em 0 0 !important;
  }

  &__header-container {
    max-width: 60.125em;
    display: flex;
    justify-content: flex-start;
    padding: 2.5em 0;
    flex: 1;
  }

  &__back-link {
    color: $navy !important;
  }

  &__container {
    border-radius: 1.25em;
    background-color: $white;
    min-height: 20em;
    width: 60.125em;
    align-items: center;
    padding: 4em 5em;
    margin-bottom: 2.5em;
  }

  &__title {
    font-family: 'Apercu';
    font-style: normal;
    font-size: 1.5em;
    font-weight: 700;
    color: $cfa-black;
    margin: 0 0 0.5em;
  }

  &__subtitle {
    font-family: 'Apercu';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875em;
  }

  &__finding-dropdown {
    .cfa-dropdown-display {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__finding-dropdown-option {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__button-container {
    display: flex;
    justify-content: end;
    margin-top: 3.5em;
  }

  &__location-options-container {
    margin-top: 0.3em;
    display: flex;
    justify-content: space-between;
  }

  &__clear-locations-button {
    font-size: 0.9em;
    letter-spacing: 0.05em;
    background-color: transparent;
    color: #0056b3;
    border-color: transparent;
    font-weight: bold;
    border: 0;
  }

  &__clear-locations-button:disabled {
    color: #5b6770;
  }

  &__submit-button {
    width: 7.125em !important;
    height: 3em !important;
    margin: 0 0 0 1em !important;
  }

  &__typeahead-label {
    font-family: 'Apercu';
    font-style: normal;
    font-weight: 500;
    font-size: 0.875em;
    color: #5b6770;
    margin: 1.5em 0 0.125em 0.0625em;
  }

  &__all-locations-label {
    font-weight: 500;
    font-size: 0.875em;
    color: #5b6770;
    margin: 0.1em 0 0 0.3em;
  }

  &__typeahead {
    width: 100%;

    .form-control {
      color: #a7acaf;
      border: 0.0625em solid #5b6770;
    }

    .rbt-input-multi.form-control {
      min-height: 3em;
    }

    .rbt-input-multi.form-control[disabled] {
      background-color: #eceeee;
    }
  }
}
