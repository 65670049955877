@import '../../../../colors';

.assessment-card {
  margin-bottom: 0.8em;

  &__content {
    margin-bottom: 0.6em;
    margin-left: 1em;
  }

  &__header-container {
    margin-bottom: 0.6em;
    margin-top: 0.6em;
  }

  &__header {
    font-size: 1.125em;
    margin-left: 0.8em;
    margin-bottom: 0.8em;
  }

  &__row {
    margin-bottom: 1em;
    margin-top: 0.5em;
  }
}

.assessment-landing {
  &__container {
    margin: 3em 0;
  }

  &__sub-title {
    color: $cfa-gray;
    margin: 0.68em 0 1.64em;
  }

  &__card-display {
    display: flex;
    gap: 10px;
  }

  &__button-group {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
  }
}

.blank-card-container {
  height: 100%;
  min-height: 22.6rem;
  overflow: visible;
  justify-content: center;
  text-align: center;
}

@media (width <= 810px) {
  .assessment-landing {
    &__container {
      max-width: 70em;
    }

    &__card-display {
      flex-direction: column;
      gap: 0;
    }
  }
}

/* Media Query for screens with max-width of 1024px */
@media screen and (max-width: 991px) {
  .staffing-column {
    margin-bottom: 2rem;
  }

  .visits-column {
    margin-bottom: 2rem;
  }

  .top-focus-area-column {
    margin-bottom: 2rem;
  }

  .oc-notes-column {
    margin-bottom: 2rem;
  }
}
