.manage-safe-score-container {
    .cfa-message-block {
        max-width: 100%;
    }

    .score-history-loading-indicator {
        display: flex;
        justify-content: center;
    }

    .card-display {
        height: 2000px
    }

}