@import '../../../../colors';

.pathway-question {
    &__finding-title {
        text-align: center;
        margin: 1em 4em 3em;
    }

    &__question-text {
        color: $cfa-black;
        text-align: center;
        font-weight: bold;
        margin: 0 2em 3em;
    }

    &__button-container {
        display: flex;
        justify-content: center;
        margin-bottom: 3em;
        flex-wrap: wrap;
    }

    &__button {
        height: 7.1em;
        width: 15.6em;
        color: $cfa-blue-dark;
        background-color: $white;
        border: solid 1px $cfa-blue-dark;
        border-radius: 4px;
        margin: 1em 2em;
        filter: drop-shadow(0 4px 4px rgb(0 0 0 / 0.25));

        &:hover {
            background-color: $cfa-blue-dark;
            color: $white;
        }

        &:active {
            color: $white;
            font-weight: bold;
            transform: scale(0.98);
            background-color: #004361;
            box-shadow: 3px 2px 22px 1px rgb(0 0 0 / 0.24);
        }

        &--active {
            font-weight: bold;
            background-color: $m-soft-teal;
            border: solid 1px $cfa-blue-dark;
            z-index: 77777;
        }
    }
}