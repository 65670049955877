@import "../../../../App";

.finding-details-card {
  background: #fff;
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: stretch;
  margin-bottom: 1.5em;
  margin-top: 1.5em;

  &__details-container {
    order: 1;
    flex: 1;
    min-width: 50%;
    @media (width >= 711px) {
      padding-right: 1em;
      margin: 1.5em;
    }
    @media (width <= 710px) {
      margin: 1em 1em 0;
    }
  }

  &__links-container {
    order: 3;
    flex: 1;
    align-self: stretch;
    @media (width >= 711px) {
      margin: 1.5em;
      border-left: 1px solid #c4c4c4;
      padding-left: 2em;
    }
    @media (width <= 710px) {
      border-top: 1px solid #c4c4c4;
      padding-top: 1em;
      margin: 0 1em 1em;
    }
  }

  &__detail-container {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    flex-wrap: wrap;
  }

  &__top-detail-container {
    margin-left: 0.1em;
    @media (width <= 710px) {
      padding-top: 1em;
    }
  }

  &__detail-element-container {
    display: flex;
    margin-bottom: 0.5em;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__detail-element {
    margin: 0 2em 0 0;
    flex: 1 1 0px;
    min-width: 12em;
    width: fit-content;
  }

  &__labels-container {
    margin: 2em 0;
  }

  &__detail-label {
    min-width: 7em;
  }

  &__detail-text {
    line-height: 1.25em;
    color: $cfa-black;
    font-weight: 500;
  }

  &__links-box {
    border-radius: 8px;
    padding: 1.875em 1.25em 1.25em;
    margin-top: 1.25em;
    box-shadow: 0 7px 20px rgb(0 0 0 / 0.16);
  }

  &__links-title {
    @media (width <= 710px) {
      font-size: 0.875rem;
      line-height: 1em;
    }
  }

  &__links-url {
    font-family: "Apercu";
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    color: #004f71;
  }

  &__links-icon {
    padding-right: 0.25em;
    padding-bottom: 0.15em;
  }

  &__links-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  &__links-list-item {
    padding: 0.5em 0;
  }

  &__image-gallery-container {
    padding: 0;
  }
}
