@import '../../../../colors';

.occurrences-container {
  &__container {
    margin-top: 2em;
  }

  &__title {
    color: $informational;
  }

  &__sub-title {
    color: $informational;
  }

  &__card-container {
    overflow: auto;
    white-space: nowrap;
  }
}
