@import '../../../../colors';

.ask-question {
  &__container {
    background-color: $white;
    max-width: 70em;
    padding: 2rem 3rem;
    width: 100%;
  }

  &__button-container {
    display: flex;
    padding-top: 3rem;
    justify-content: end;
    @media (width <= 500px) {
      flex-direction: column;
    }
  }

  &__text-input {
    padding-top: 2rem;
  }

  &__link-text {
    padding-top: 1rem;
  }
}