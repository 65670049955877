@import '../../../../../../colors';

.visits-card {
  &__subtitle {
    color: $cfa-gray;
    margin-bottom: 0.2em;
  }

  &__visit-subtitle {
    color: $cfa-gray;
  }

  &__last-visit-container {
    display: flex;
    width: 100%;
  }

  &__calendar {
    color: $teal;
   
  }

  &__subtitle-container {
    display: flex;
    flex-direction: column;
    max-width: 10rem;

  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__last-visit-subtitle-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 20rem;
  }

  &__monthly-data-container {
    display: flex;
    justify-content: space-between;
  }

  &__card-container {
    margin: 0 1rem;
  }

  &__general-date-info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-left: 1rem;
  }
}

@media (width <= 810px) {
  .visits-card {
    width: auto;
  }
}
