.download-modal {
  &__header,
  &__body {
    text-align: left;
  }

  &__icon {
    width: 2.5rem;
    height: 2.5rem;
    color: #004f71;
    margin-bottom: .5rem;
  }

  &__no-lbr-reports {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    align-items: center;
    justify-content: center;


  }

  &__footer {
    justify-content: flex-end;
  }
}

.download-button {
  &__icon {
    path:not(mask path) {
      fill: none;
    }
  }
}
