@import '../../../../App';

.confirmation-card {
  margin-top: 40px;
  height: max-content;

  &__container {
    display: flex;
    align-items: flex-start;
    margin-bottom: 2em;
  }

  &__label {
    line-height: 24px;
    color: #5b6770;
    margin-bottom: 0;
    width: 130px;
  }

  &__info {
    color: #5b6770;
    margin-bottom: 0;
    max-width: 600px;
  }

  &__location-info {
    color: #5b6770;
    flex: 1 0 40%;
    flex-shrink: 0;
  }

  &__location-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-shrink: 1;
    width: 650px;
  }

  &__button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 5em;
  }

  &__occurrences-container {
    overflow: auto;
    white-space: nowrap;
    width: 600px;
  }
}
