@import "../../../../App";

.d-block.d-sm-none > .menu-container {
  margin-top: 1rem;
}

.nav-container {
  overflow: hidden !important;
  position: relative;
  top: 0;
  left: 0;
  z-index: 99;
  opacity: 0.9;
  display: flex;
  align-items: center;
  background-color: $white;
  width: 100%;
  color: $dark-gray;
  font-family: "Apercu-Regular";
  padding: 1rem 2rem;
  margin-bottom: -1.5rem;
  justify-content: flex-start;

  .title {
    font-size: 1.1rem;
    padding: 0 1em;
  }

  .menu-container > {
    overflow-y: hidden !important;
    top: 1.5rem;
    left: 0;
    height: 95%;
    background-color: white !important;
    position: fixed;
    width: 100%;
    display: flex;
    flex-direction: column;
    background: white;
    opacity: 0.95;
    color: #fafafa;
    transition: height 0.3s ease;
    z-index: 2;
    padding-top: 2rem;
  }

  .menu-button-container {
    height: 32px;
    width: 32px;
    display: flex;
    background-color: white;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 4px;
  }

  img {
    cursor: pointer;
  }
}
