@import "../../../App";

.HealthAndHygiene {
  background-color: $white-gray;
  text-align: center;
  max-width: 35rem;
  margin-top: 1rem;
  justify-self: start;

  @media (width >= 992px) {
    max-width: 45rem;
  }

  h2 {
    margin-top: 1rem;
    font-size: 1.55rem;
    font-family: "Apercu-Regular";
    text-align: left;
  }

  .app-card {
    margin: 0 auto;
    padding: 1.5rem 1.25em;
    height: 11rem;
    width: 15.5rem;

    h3 {
      font-size: 1.3rem;
      font-family: "Apercu-Medium";
      color: $navy;
    }

    .app-card-icon {
      margin-top: 1.5rem;
      height: 3.25rem;
      width: 3.5rem;
      align-self: flex-end;
    }

    .report-icon {
      height: 3rem;
    }
  }

  .app-card:hover {
    text-decoration: underline;
    text-decoration-color: $dark-gray;
    cursor: pointer;
  }
}
