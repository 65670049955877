@import "../../../App";

.FindingsFilter {
  border: none;
  padding: 2rem;
  pointer-events: auto;

  .sub-header {
    font-size: 0.9rem;
    font-family: "Apercu-bold";
    text-align: left;
    color: $dark-gray;
    margin-bottom: 0.5rem;
  }

  .radio-item {
    margin-bottom: 0.75rem;
    font-size: 0.75rem;

    input {
      margin-top: 0.15rem;
      height: 0.8rem;
      background-color: green !important;
      color: orange !important;
    }

    .form-check-label {
      color: $dark-gray;
    }

    margin-left: 0.5rem;
  }

  .resolved-switch-container {
    width: 12rem;
    margin: 0 0 -0.5rem -1rem;
    padding: 0.5rem 0 1.25rem;

    span {
      margin-left: 0.5rem;
      font-size: 0.75rem;
    }
  }

  .clear-filters {
    margin: -1rem 0.5rem 0.5rem -0.5rem;
    font-size: 0.8rem;
    color: $teal;
    font-family: "Apercu-bold";
    flex-direction: row;
    cursor: pointer;
  }

  .clear-filters {
    color: $link-blue !important;
  }

  .close-icon {
    margin: -1.6rem 0.75rem 0 -0.5rem;
    align-self: center;
  }

  .filter-list {
    padding: 0;
    margin: 0;

    .filter-by {
      margin: 1.5rem 0 0.5rem -2rem;
      font-size: 0.8rem;
    }

    .filter-link {
      margin: 0.5rem -0.5rem;
      font-size: 0.75rem;
      color: $teal;
      font-family: "Apercu-medium";
      border-bottom: 1px solid $gray-border;
      flex-direction: row;
      cursor: pointer;
    }

    .filter-link.label-is-selected {
      color: $secondary-purple !important;
      pointer-events: none;
    }

    span {
      color: $default;
    }
  }

  .dimmed-view {
    opacity: 0.6;
    pointer-events: none;
  }
}
