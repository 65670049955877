.restaurant-sales-card {
  &__previous-sales-number {
    white-space: nowrap;
  }

  &__current-sales-number {
    white-space: nowrap;
  }

  @media (max-width: 767px) {
    &__sales-span,
    &__last-year-in,
    &__total-sales-in,
    &__previous-sales-number,
    &__current-sales-number {
      white-space: normal;
    }
  }

  &__title-container {
    display: flex;
    justify-content: space-between;
    align-items: start;
  }

  &__no-data-message {
    padding-bottom: 2.5rem;
    padding-top: 2.5rem;
  }

  &__loading-indicator {
    padding-top: 3.375rem;
    padding-bottom: 3.25rem;
  }

  &__dropdown {
    padding: 0 1rem 1rem;

    .cfa-input-label.cfa-dropdown-label {
      display: none;
    }
  }

  &__card-container {
    overflow: visible;
    height: 100%;
    padding-bottom: 1rem;
    align-items: center;
  }

  &__card-content-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__other-state-card-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__sales-column {
    display: flex;
    align-items: center;
    padding-bottom: 1rem;
  }

  &__current-total-sales-container {
    margin-left: 1rem;
  }

  &__previous-total-sales-container {
    margin-left: 1rem;
  }

  &__sales-span {
    font-weight: bold;
    margin-left: 0.25rem;
    white-space: nowrap;
  }

  &__last-year-in {
    white-space: nowrap;
  }

  &__total-sales-in {
    white-space: nowrap;
  }

  &__update-column {
    padding-bottom: 1rem;
    padding-right: 2rem;
    padding-left: 2rem;
  }

  @media (max-width: 991px) {
    &__update-column {
      display: flex;
      flex-direction: column;
      align-items: end;
    }

    &__send-a-link-button {
      width: 20%;
    }
  }

  &__latest-update {
    margin-bottom: 1rem;
  }

  &__gray-divider {
    height: 60%;
    background-color: #cccfd0;
    width: 2px;
    margin: 0 16px;
  }
}
