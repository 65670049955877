@import '../../../../../../colors';

.staffing-card {
  // width: 31em;

  &__title {
    font-size: 0.875em;
    color: $default;
    line-height: 1.3em;
  }

  &__name {
    font-size: 0.875em;
    color: $default;
  }

  &__col {
    margin-bottom: 0;
  }
}

@media (width <= 810px) {
  .staffing-card {
    width: auto;
  }
}
