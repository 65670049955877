@import '../../../colors';

.file-uploader {
  &__add-images-btn {
    margin-top: .5rem;
    min-width: auto !important;
    padding: 0 !important;

    &:focus {
      outline: 0 !important;
    }
  }

  &__container {
    margin: 3em 0;
  }

  &__form-container {
    border: 1px dashed $default;
    border-radius: 5px;
    height: 7em;

    &:hover {
        border: 3px dashed $cfa-blue-dark;
    }

    &--is-uploading {
      background-color: $disabled-gray;
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &__form {
    height: 100%;
  }

  &__input-wrapper {
    overflow: hidden;
    position: relative;
    cursor: pointer;
    text-align: center;
    height: 100%;
  }

  &__input-label {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    
    &:hover {
      cursor: pointer;
      color: $cfa-blue-dark;
    }
  }

  &__hidden {
    /* Opacity settings for all browsers */
    opacity: 0;
    -moz-opacity: 0;
    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
  }

  &__file-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    box-shadow: 0 7px 20px rgb(0 0 0 / 0.16);
    padding: 0.5rem 1rem;
    margin: 1rem 0;

    &__flex-container {
      display: flex;
      align-items: center;
    }

    &__file-name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 1rem;
    }

    &__status-container {
      margin-right: .5rem;
    }

    &__status-icon {
      margin: -1px 7px 0 0;
    }

    &__delete-btn {
      display: flex;
      border-radius: 8px;
      padding: .25rem .75rem;
      margin-left: .5rem;
      background-color: transparent;
      border: 1px solid $disabled-gray;

      &:hover {
          cursor: pointer;
          background-color: $disabled-gray;
      }
    }
  }
}