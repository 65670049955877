@import "./colors";
@import "~react-image-gallery/styles/scss/image-gallery";

/* Fonts */

@font-face {
  font-family: "Apercu-medium";
  src: url("./app/assets/fonts/Apercu-medium.otf") format("opentype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "Apercu-bold";
  src: url("./app/assets/fonts/Apercu-bold.otf") format("opentype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "Apercu-regular";
  src: url("app/assets/fonts/Apercu-regular.otf") format("opentype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "Open-sans";
  src: url("app/assets/fonts/OpenSans-Regular.ttf") format("opentype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "Roboto";
  src: url("app/assets/fonts/Roboto-Regular.ttf") format("opentype"); /* Safari, Android, iOS */
}

* {
  font-family: "Apercu-regular";
}

html,
body {
  height: 100%;
}

body {
  overflow-x: hidden;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $white-gray !important;
}

#root {
  background-color: $white-gray !important;
}

svg {
  vertical-align: baseline;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
p {
  color: $default;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  font-family: "Apercu-bold";
}

h4,
.h4 h5,
.h5 h6,
.h6 p,
a {
  font-family: "Roboto";
}

.button {
  font-family: "Apercu-bold" !important;
}

button:active,
button:focus,
button:focus:active {
  background-image: none !important;
  outline: 0;
  box-shadow: none !important;
}

.card {
  border: 0.5px solid $nav-border !important;
  border-radius: 2px;
}

// Style App scrollbar
::-webkit-scrollbar {
  height: 0.25rem;
  width: 0.25rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: $white-gray;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $light-gray;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $default;
}

/* Tooltip */
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: $dark-gray;
}

.tooltip-inner {
  background-color: $dark-gray;
  border-radius: 1px;
}

.tooltip.show {
  opacity: 1 !important;
  z-index: 100 !important;
}

.cfa-card {
  background-color: white;
}

.cfa-loading-indicator {
  margin: auto;
}

.base-popper {
  overflow: auto;
}

.cfa-button.button-variant-outlined {
  // this un-does the style being applied from ots-components-library icons
  background-color: white !important;
}

// Overwrites the design system's Typography component default to (gray8 / cfa black)
.cfa-typography-color__default {
  color: $cfa-black;
}

// A class to revert the above overwrite in specific cases
// These 2 classes can be changed if/when the design system allows more color options
.force-cfa-gray {
  color: $cfa-gray;
}

.cfa-multiselect-list {
  max-height: 260px; 
}
