@import '../../../../colors';

.select-audit-occurance {
  &__container {
    margin-bottom: 2em;
  }

  &__title-container {
    margin-bottom: 1.5em;
  }

  &__title {
    color: $cfa-black;
    font-weight: bold;
    letter-spacing: 0.05em;
    font-size: 1.4rem;
  }

  &__sub-title {
    font-size: 15px;
    color: $cfa-black;
    margin-bottom: 1.5em;
  }

  &__sub-heading {
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0.05em;
    color: $cfa-black;
  }

  &__division-line {
    height: 2px;
    background: $disabled-gray;
    border-radius: 4px;
    position: relative;
  }

  .css-yk16xz-control {
    border-radius: 4px;
    border-color: $cfa-black;
    margin-top: -15px;
  }

  .css-1wa3eu0-placeholder {
   font-size: 0.95em;
  }

  .css-1n7v3ny-option {
    color: black;
    background-color: $white;
  }

  .css-9gakcf-option:hover,
  .css-yt9ioa-option:hover,
  .css-1n7v3ny-option:hover {
    cursor: pointer;
    color: black;
    background-color: rgb(224 235 253);
  }
}