
.appeals-card {
  &__tag-header {
    display: flex;
  }

  &__left-tags {
    flex-grow: 1;
    display: flex;

    @media (width <=992px) {
      flex-direction: column;
    }
  }

  &__requester-display-name {
    margin-right: 1.5rem;
  }

  &__requester {
    margin-right: .3rem;
    line-height: 1.8rem;
  }

  &__operator-comment-label {
    text-decoration: underline;
  }
  
  &__touchpoint-container {
    display: flex;
    margin-top: 1rem;
  }

  &__touchpoint {
    margin-right: 15rem;
  }
  
  &__issue-text-container {
    margin-top: 1rem;
  }

  &__attachment-url {
    width: fit-content;
    font-weight: 1000;
  }

  &__attachment-container {
    display: flex;
    flex-direction: column;
  }

  &__reason-for-review {
    overflow-wrap: anywhere;
  }

  &__reason-info-container {
    margin-top: .5em;
    margin-right: 2em;

    
  }
}

.download-button {
  &__icon {
    padding-right: .3rem;
    
    path:not(mask path) {
      fill: none;
    }
  }
}