@import '../../../../colors';

.select-finding-form {
  &__container {
    background-color: $white;
    max-width: 70em;
    padding: 2rem;
    width: 100%;
  }

  &__label-star {
    padding-left: .3rem;
    color: red;
  }

  &__finding-container{
    display: flex;
    justify-content: space-between;
    @media (width <= 800px) {
      flex-direction: column;
    
    }
  }

  &__button-container{
    display: flex;
    margin-top: 2rem;
    justify-content: end;
    @media (width <= 500px) {
      flex-direction: column;
    }
  }

  &__next-button{
    margin-left: 1rem;
    @media (width <= 500px) {
      margin-left: 0;
      margin-top: 1rem;
    }
  }
}