@import '../../../App';

.admin {
  &__page-title-row {
    justify-content: center;
    margin-top: 5rem;
  }

  &__page-subtitle-row {
    justify-content: center;
    margin-bottom: 2rem;
  }

  &__navigation-row {
    justify-content: center;
  }

  &__navigation-column {
    justify-content: center !important;
    align-items: center !important;
    margin-bottom: 5rem;
  }

  &__navigation-card {
    justify-content: center;
    align-items: center;
    height: 17rem;
    width: 22rem;
  }

  &__navigation-card:hover {
    color: #004e71;
    border: solid #004e71 3px !important;
  }

  &__navigation-card-image {
    margin-bottom: 2rem;
  }

  &__navigation-link {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
