@import "../../../../App";

.SelfAssessment {
  background-color: $white-gray;
  max-width: 40rem;
  margin-top: 1rem;
  @media (width >= 992px) {
    max-width: 45rem;
  }

  .kn-multi_select__wrapper___30BEc,
  .multi-select-container {
    height: 15rem !important;
    width: 100%;
  }

  input .jss9 {
    font-size: 0.9rem;
  }

  .kn-item_label__label___2fJ5p {
    margin-top: 0.1rem;
    font-size: 0.9rem;
  }

  .kn-no_items__no_items___3ON4R {
    margin-top: -5rem;
  }

  .multi-select-input {
    font-size: 0.9rem;
    font-family: "Apercu-medium";
    color: $default;
    margin-bottom: -0.2rem;
  }

  .assess-title-container {
    margin: 0.25rem 0 0.75rem;
    padding-left: 3rem;
    justify-content: center;
    text-align: center;

    .assess-title-icon {
      text-align: center;
      margin: auto;
      justify-content: center;
    }

    .assess-title {
      margin: 1rem auto;
      font-size: 1.2em;
    }
  }

  .self-assessment-card {
    padding: 1rem 2rem;
    margin: 1rem auto;
    width: 70%;

    @media only screen and (width <= 404px) {
      width: 100%;
    }

    @media (width >= 405px) and (width <= 767px) {
      width: 80%;
    }

    @media (width >= 992px) {
      max-width: 45rem;
    }

    .input-row {
      margin: 0 auto 2rem;
      z-index: -1;

      @media only screen and (width <= 404px) {
        width: 100%;
      }

      @media (width >= 405px) and (width <= 767px) {
        width: 80%;
      }

      @media (width >= 992px) {
        width: 90%;
      }
    }

    .input-label {
      font-size: 0.9rem !important;
      font-family: "Apercu-medium";
      color: $dark-gray;
    }
  }

  .desktop-back-link {
    font-size: 1rem;
    text-align: left;
    color: $dark-gray;
    cursor: pointer !important;
  }

  .desktop-back-arrow {
    margin-right: 0.1rem;
  }

  .self-assessment-form {
    .store-location-input {
      width: 100%;

      .store-location-input-option {
        font-size: 0.85rem;
        font-family: "Apercu-Bold";
        color: $navy;
      }
    }

    .store-location-icon {
      height: 1.1rem;
      margin: 1rem;
      position: absolute;
      left: 88.5%;
      top: -0.5rem;
      justify-content: end;
      z-index: 10;
    }

    .dropdown-item.active,
    .dropdown-item:active {
      background-color: $teal-outline;
      color: $secondary-purple !important;
    }

    // Store Number //
    [type="text"] {
      background-color: white;
      background-size: 1.75rem;
      z-index: 10;
      cursor: pointer;
      margin-bottom: 0.1rem;
    }

    [type="text"]::-webkit-inner-spin-button {
      display: none;
    }

    [type="text"]::-webkit-calendar-picker-indicator {
      opacity: 0;
    }

    input[type="text"]::-webkit-inner-spin-button {
      appearance: none;
      display: none;
    }

    input[type="text"]::placeholder {
      color: $light-gray;
    }

    // Firefox fix
    @-moz-document url-prefix() {
      [type="date"] {
        background: #fff url("../../../assets//icons/calendar.svg") 97% 50% no-repeat;
        background-size: 1.35rem;
        cursor: pointer;
      }
    }

    // Date //
    [type="date"]::-webkit-inner-spin-button {
      display: none;
    }

    [type="date"]::-webkit-calendar-picker-indicator {
      color: rgb(0 0 0 / 0);
      opacity: 1;
      display: block;
      background: #fff url("../../../assets//icons/calendar.svg") 97% 50% no-repeat;
      background-size: 1.35rem;
      height: 1rem;
      width: 1rem;
      margin-right: -2.3rem;
      background-color: white;
      cursor: pointer;
    }

    input[type="date"]::-webkit-inner-spin-button {
      appearance: none;
      display: none;
    }

    // Type //
    select {
      appearance: none;
      cursor: pointer;
    }

    input[id="select"]::-webkit-inner-spin-button {
      appearance: none;
      display: none;
    }

    [id="select"] {
      background: #fff url("../../../assets//icons/down-arrow.svg") 96.5% 50% no-repeat;
      background-size: 0.75rem;
      background-color: white;
      z-index: 10;
      cursor: pointer;
    }

    [id="select"]::-webkit-inner-spin-button {
      display: none;
    }

    [id="select"]::-webkit-calendar-picker-indicator {
      opacity: 0;
    }

    // Details //
    [id="details"] {
      background: #fff url("../../../assets//icons/info-icon.svg") 97.5% 50% no-repeat;
      background-size: 1.1rem;
      background-color: white;
      z-index: 10;
      cursor: pointer;
    }

    [id="details"]::-webkit-inner-spin-button {
      display: none;
    }

    [id="details"]::-webkit-calendar-picker-indicator {
      opacity: 0;
    }

    #details::placeholder {
      color: $light-gray;
    }

    .text-area-icon {
      height: 20px;
      width: 20px;
      margin: 1rem;
      position: absolute;
      left: 90%;
      z-index: 10;
    }

    .cfa-multiselect-height {
      height: 5em;
    }

    .form-control {
      border-radius: 2px;
    }

    input.form-control,
    select,
    date,
    input::placeholder {
      color: $dark-gray;
    }

    label.input-label {
      font-size: 1rem;
    }

    input.form-control,
    select.form-control {
      font-size: 0.86rem;
      padding-right: 3rem;
    }
  }

  .selection-error {
    font-size: 0.85rem;
    font-family: "Apercu-Medium";
    margin: 1rem auto -1rem;
    text-align: center;
    color: $primary-dark;
  }

  .error {
    font-size: 0.85rem;
    font-family: "Apercu-Medium";
    margin: -1rem auto 2.25rem;
    text-align: center;
    color: $primary-dark;
  }

  .submit-button {
    margin-top: -0.5rem;
    margin-bottom: 0.5rem;
    font-size: 0.85rem;
    font-weight: bold;
    align-self: center;
    height: 2.5rem;
    width: 10rem;
    color: $white;
    border-color: $navy;
    background-color: $navy;
    border-radius: 3rem;
  }

  .submit-button:hover {
    background-color: $secondary-purple;
    border: $white;
  }

  .submit-button:disabled {
    cursor: pointer;
    background-color: $light-gray;
    border-color: $light-gray;
  }

  .submit-button:disabled:hover {
    background-color: $light-gray;
  }

  .success-container {
    text-align: center;

    .success-icon {
      margin-top: 2rem;
    }

    h4 {
      color: $default;
      margin-top: 2rem;
    }

    .go-back-button {
      margin: 3rem auto 2rem;
      font-size: 0.85rem;
      font-weight: bold;
      align-self: center;
      height: 2.5rem;
      width: 13rem;
      color: $white;
      border-color: $navy;
      background-color: $navy;
      border-radius: 3rem;
    }

    .go-back-button:hover {
      background-color: $secondary-purple;
      border-color: $secondary-purple;
    }
  }
}
