@import "../../../../App";

.appeals {
  &__header {
    display: flex;
    justify-content: space-between;

    @media (width <=992px) {
      flex-direction: column;
    }
  }

  &__filter-container {
    display: flex;
    justify-content: flex-end;
    gap: 2rem;
    align-items: flex-end;
    width: 40rem;

    @media (width <=992px) {
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__search input {
    padding: 7px 11px;
  }

  &__round-dropdown {
    width: 15rem;
  }

  &__no-appeals-title {
    margin-top: 1em;
    padding: 3em 0;
    text-align: center;
    background: $white;
    border-radius: 8px;
  }

  &__pagination {
    justify-content: center;
  }
}