@import "../../../App";

.landing {
  background-color: $white-gray;
  text-align: center;
  max-width: 35rem;
  margin-top: 1rem;
  justify-self: start;

  &__tile_title{
    margin-bottom: 8px;
  }

  &__title_container {
    display: flex;
    justify-content: center;
  }

  &__link {
    text-decoration: none !important;
  }

  @media (width >= 992px) {
    max-width: 45rem;
  }

  h1.landing-greeting {
    text-align: center;
    font-size: 2rem;
    margin: 3em 0 1.5em;
    color: $cfa-black;

    // Mobile
    @media (width <= 600px) {
      margin: 1.4em 0 1em;
    }

    span {
      color: $secondary-purple  !important;
      font-family: "Apercu-bold";
    }
  }

  h2.landing-message {
    font-size: 1.2rem;
    color: $default;
    margin: 1rem auto;
    font-family: "Apercu-medium";
  }

  h3 {
    font-size: 1.55rem;
  }

  .sign-in-card {
    margin: 2rem auto;
    padding: 1.5rem 2.5rem;
    max-width: 30rem;
  }

  .app-card {
    margin: 0 auto;
    padding: 1.5rem 1.25em;
    height: 14.5em;
    width: 19.5em;
    box-shadow: 0 1px 8px rgb(0 0 0 / 0.16);
    border-radius: 8px;
    border: solid 3px rgb(0 0 0 / 0) !important;

    h3 {
      font-size: 1.45rem;
      font-family: "Apercu-Medium";
      color: $cfa-black;
    }

    .app-card-icon {
      margin-top: 2em;
      height: 5em;
      width: 5em;
      align-self: flex-end;
      position: relative;
    }
  }

  .app-card:hover {
    color: $cfa-blue-dark;
    border: solid $cfa-blue-dark 3px !important;
  }

  .app-card:hover h3 {
    color: $cfa-blue-dark;
    text-decoration: none;
  }

  .landing-icon {
    margin: 2rem auto;
    width: 8rem;
    display: block;
  }

  .sign-in-button {
    margin-top: 1rem;
    font-size: 0.85rem;
    font-weight: bold;
    align-self: center;
    height: 2.5rem;
    width: 10rem;
    color: $white;
    border-color: $navy;
    background-color: $navy;
    border-radius: 3rem;
  }

  .sign-in-button:hover {
    background-color: $secondary-purple;
    border: $white;
  }
}