@import '../../../../App';

.ReportDashboard {
  background-color: $white-gray;
  text-align: center;

  #header-row {
    align-items: center;
    margin-bottom: 20px;
  }

  .cfa-card {
    margin-bottom: 30px;
  }

  .card-wrapper {
    display: flex;
  }

  .dashboard-card {
    .performance-link {
      text-align: left;
      font-size: 0.8rem;
      text-decoration: underline;
      color: $navy;
    }

    .performance-chart-outer-container {
      margin: -1em auto;
      min-height: 13.1rem;
      min-width: 21rem;

      @media (width <= 683px) {
        margin: auto;
        max-width: 15rem;
      }
    }

    span.coming-soon {
      font-size: 0.9rem;
      padding-top: 4rem !important;
      color: $secondary-purple;
    }

    span.score-coming-soon {
      font-size: 0.9rem;
      margin-top: 1rem !important;
      color: $secondary-purple;
    }
  }

  td {
    cursor: pointer;
  }

  td:hover {
    text-decoration: underline;
  }

  .findings-summary-card {
    width: 100%;
    /* stylelint-disable-next-line media-feature-range-notation */
    @media (max-width: 1024px) {
      padding-bottom: 3rem;
    }

    th,
    td {
      text-align: center;
      vertical-align: middle;
    }

    tr {
      font-size: 0.9rem;
      height: 60px;
    }

    td {
      font-size: 0.85rem;
      border: none;
    }

    .table th {
      padding: 0;
      background-color: $white;
      border: none;
    }

    th:first-of-type {
      border: none;
      font-size: 0.8rem;
      margin-bottom: 0.5rem;
    }

    tr:first-of-type {
      background-color: $white;
    }

    tr:nth-child(odd) {
      border: none;
      background-color: $white-gray;
    }

    .findings-type {
      font-size: 0.75rem;
      padding-bottom: 1rem !important;
    }

    .row-label {
      vertical-align: middle;
      text-align: left;
    }

    .findings-summary-item-col {
      text-align: left;
      padding: 0.5rem 0 0;

      .findings-item-row {
        padding: 0.25rem 0;
        justify-content: space-between;
      }

      h5 {
        font-size: 0.95rem;
        font-family: 'Apercu-Regular';
      }

      .count {
        padding-right: 3rem;
        text-align: right;
        font-family: 'Apercu-medium';
      }

      hr {
        width: 100%;
        color: $light-gray;
        height: 1px;
        margin: 0.15rem 0;
      }

      .immediate {
        color: $barbecue;
      }

      .high {
        color: $primary-dark;
      }

      .medium {
        color: $spicy-honey-mustard;
      }

      .low {
        color: $honey-mustard;
      }

      .informational {
        color: $navy;
      }

      .bold {
        font-size: 0.875rem;
        font-family: 'Apercu-bold';
      }
    }
  }

  .no-location-message {
    font-size: 1.25rem;
    margin-top: 3rem;
  }

  .footer-disclaimer {
    padding: 2rem;
    margin: 0 auto;
    text-align: left;
    font-family: 'Apercu-Regular';
    font-size: 0.8rem;
    color: $dark-gray;
  }

  .food-safety-faq {
    color: $navy;
    font-size: 0.8rem;
    text-decoration: underline !important;
  }

  span {
    color: $teal;
    font-family: 'Apercu-medium';
  }

  .request-amendment-container {
    display: flex;
    margin-top: -2.8em;
    justify-content: flex-end;
    font-size: 14px;

    @media (width <= 683px) {
      margin: 2em 0 0;
      justify-content: left;
    }
  }
}
