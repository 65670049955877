

.no-audits-container {
    padding: 3rem 0;

		.no-audits-title {
			margin-bottom: .75rem;
		}

		.no-audits-icon {
			width: 2.5rem;
			height: 2.5rem;
			color: #004f71;
			margin-bottom: .5rem;
		}
  }