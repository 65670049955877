.tab-layout {
  &__breadcrumbs {
    padding: 1.5rem 0;
  }

  &__title-container {
    display: flex;
  }
  
  &__tabs-list {
    flex-grow: 1;
    
    .cfa-tabs-list {
      gap: 2rem;
      border-bottom-width: 3px;
    }
    
    .cfa-tab-item-wrapper {
      margin-bottom: -2px;
    }

    .cfa-horizontal-slider {
      height: 2px;
      top: auto;
      bottom: 0;
    }
  }

  &__tab-item {
    button {
      padding-left: 0;
      padding-right: 0;
      margin-bottom: .5rem;
      font-weight: 400 !important;
    }
  }

  &__tabs-container{
    display: flex;
    justify-content: space-between;
  }

  &__button-container {
    border-bottom: 1px solid #cccfd0;
    border-bottom-width: 3px;
  }
}