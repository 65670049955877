@import "../../../App";

.list-card {
  position: relative;
  padding: 0;
  margin-bottom: 1rem !important; // required due to non-specific styling in cfa-card in FoodSafetyReport.scss
  border-radius: .5rem !important;

  &__right-arrow {
    text-align: right;
    align-self: center;
  }

  &__past-finding {
    background-color: $disabled-gray !important;
  }

  &__close-button {
    position: absolute;
    top: .5rem;
    right: 1rem;
    z-index: 1;
  }

  .cfa-card-content {
    padding: 1.5rem 2rem;
  }

  .risk-level-accent {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-top-left-radius: .5rem;
    border-bottom: 1.5rem solid transparent;
  
    &__immediate {
      border-left: 1.5rem solid #ca2222;
    }
  
    &__high {
      border-left: 1.5rem solid rgb(226 56 3 / 0.8);
    }
  
    &__medium {
      border-left: 1.5rem solid rgb(173 81 30 / 0.5);
    }
  
    &__low {
      border-left: 1.5rem solid rgb(254 210 55 / 0.5);
    }
  
    &__informational {
      border-left: 1.5rem solid rgb(212 212 212 / 0.7);
    }
  }

  &.compact {
    margin-bottom: .5rem !important; // required due to non-specific styling in cfa-card in FoodSafetyReport.scss
    border-radius: .25rem !important;

    .cfa-card-content {
      padding: .5rem 1rem;
    }

    .risk-level-accent {
      border-top-left-radius: .25rem;
      border-width: 1rem;
    }
  }

  .cfa-surface {
    background-color: $white-gray;
  }
  
  .loading-indicator-wrapper {
    margin-top: 20px;
  }
}

.cfa-drawer-panel.drawer-width-narrow {
  width: 1300px;
  max-width: 95%; // for mobile
  min-width: 80%;
}


