@import '../../../../App';

.occurrence-card {
  display: inline-block;
  color: white;
  text-align: start;
  padding: 0.875em;
  text-decoration: none;
  box-shadow: 0 0 4px rgb(0 0 0 / 0.1);
  border-radius: 4px;
  margin: 0.5em;

  &__occurrence-selected {
    border: 2px solid #004f71;
  }

  &__info-container {
    display: flex;
    align-items: baseline;
  }

  &__label {
    color: #1e272f;
    margin-right: 0.5em;
  }

  &__description {
    color: #1e272f;
  }
}
