.historical-trend-widget {
    &__title-container {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }

    &__title-text {
        font-weight: 700;
        line-height: 20px;
        color: #1E272F;
        margin: 0.21em 0 0 0.31em;
        font-size: 20px;
        padding-right: 2.53em;
    }

    &__dropdown-menu {
        margin-top: -2em !important;
        max-width: 12.5em;

        // Break point
        // iPad
        @media (width <= 1022px) {
            margin-top: -0.51em !important;
        }

        // Break point
        // Mobile
        @media (width <= 510px) {
            margin-top: -0.5em !important;
            margin-left: 0.4em !important;
        }
    }
}