@import "../../../App";
@import "../../../colors";

.navbar {
  background-color: $white;
  justify-content: center;
  text-align: center;
  padding-right: 1.25rem;
  border-bottom: 1px solid $nav-border;

  .nav-app-logo {
    margin-top: -1rem;
    position: absolute;
  }

  .nav-app-logo-container {
    cursor: pointer;
  }

  .admin-navbar-brand {
    @media (width <= 475px) {
      display: none;
    }

    h3 {
      margin: auto -2.5rem auto 2rem;
      font-size: 1.2rem;
      font-family: "Apercu-medium";

    }
  }

  &__item {
    list-style-type: none;

    &--first{
      margin-left: 4.2em;
    }
  }

  &__link {
    color: $default;
    cursor: pointer;
    margin-right: 2.75em;
    font-family: 'Apercu';
    font-style: normal;
    font-weight: 700;
    font-size: 1.05rem;
    line-height: 25px;
    list-style-type: none;

    span:hover {
      color: $navy;
      text-decoration: none;
    }
  }

  &__link-selected {
    color: $navy;
    padding-bottom: 0.75em;
    border-bottom: solid 6px;
  }

}

.location-selector-icon {
  margin-right: 1rem;
  justify-content: center;
  align-self: center;
}

.location-selector-icon:hover {
  cursor: pointer;
}

.location-selector-card {
  button {
    background-color: $navy;
  }
}

.selected-location-container {
  margin-right: 0.6rem;
  justify-content: flex-end;

  .selected-location-number {
    font-size: 0.85rem;
    font-family: "Apercu-Medium";
    color: $teal;
    justify-content: flex-end !important;
    text-align: right !important;
  }

  .selected-location-name {
    font-size: 0.9rem;
    font-family: "Apercu-Medium";
    color: $navy;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 10.5rem;
    display: block;
    overflow: hidden;
    text-align: right;

    @media (width >= 992px) {
      width: 12.5rem;
    }
  }
}

.nav-avatar {
  justify-content: center;
  align-self: center;
}

.nav-avatar:hover {
  cursor: pointer;
}

.nav-title {
  font-family: 'Apercu';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #004F71;
  cursor: pointer;
}

.card.nav-avatar-menu {
  justify-content: center;
  text-align: center;
  min-height: 2rem;
  width: 15rem;

  .nav-admin-link {
    font-size: 1rem;
    font-family: "Apercu-medium";
    cursor: pointer;
    color: $default;

    &:hover {
      text-decoration: underline;
      color: $secondary-purple;
    }
  }

  h3 {
    color: $navy;
    font-size: 1.1rem;
    position: relative;
  }

  h4 {
    color: $default;
    font-size: 0.75rem;
    padding: 0.5rem;
  }

  div.sign-out-link {
    color: $primary-dark;
    font-family: "Apercu-Bold";
  }

  div.sign-out-link:hover {
    color: $primary-dark;
    text-decoration: underline;
    cursor: pointer;
  }

  .language-selection-heading {
    margin-top: 1rem;
    font-family: "Apercu-medium";
    font-size: 0.9rem;
  }

  .language-selection {
    margin-left: 3.5rem;
  }

  .loading-translation {
    font-family: "Apercu-medium";
    font-size: 0.9rem;
  }

  .radio-item {
    display: inline-block;
    position: relative;
    padding: 0 6px;
    margin: 2px 0 0;
  }

  .radio-item input[type="radio"] {
    display: none;
  }

  .radio-item label {
    cursor: pointer;
  }

  .radio-item label::before {
    content: " ";
    display: inline-block;
    position: relative;
    top: 5px;
    margin: 0 5px 0 0;
    width: 20px;
    height: 20px;
    border-radius: 11px;
    border: 2px solid $navy;
    background-color: transparent;
  }

  .radio-item input[type="radio"]:checked+label::after {
    border-radius: 11px;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 9px;
    left: 10px;
    content: " ";
    display: block;
    background: $navy;
    cursor: pointer !important;
  }
}