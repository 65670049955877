.pointing-tooltip {
  padding: 0.5rem;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 6px #b2b2b2;
  margin: 5px;
  min-width: 275px;
  position: relative;
}

.pointing-tooltip-carrot {
  background-color: white;
  box-shadow: 2px 2px 2px 0 rgba(178, 178, 178, 0.4);
  content: '\00a0';
  display: block;
  height: 10px;
  width: 10px;
  position: absolute;
  bottom: -5px;
  transform: translateX(-50%) rotate(45deg);
}
