@import '../../../App';

.fs-modal {
  background-color: rgba($color: $cfa-black, $alpha: 0.2);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;

  &__container {
    background-color: $white;
    width: 48em;
    padding: 2.53em 2.3em;
    border-radius: 0.5em;
    box-shadow: 0 2px 4px rgb(0 0 0 / 0.2);

  }
}